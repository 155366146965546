/** @format */

import { ClockIcon } from '@heroicons/react/24/outline';
import { Avatar, Box, Stack, Typography, alpha } from '@mui/material';
import React from 'react';

interface TaskProps {
	task: string;
	status: string;
	color?: string;
	icon: React.ReactNode;
	deadline?: string;
}

const Task: React.FC<TaskProps> = ({ task, status, icon, deadline, color }) => {
	return (
		<Box height='100%' width='100%' display='flex' mb={1}>
			<Box display='flex' alignItems='center' width='100%' height='100%'>
				<Stack flex={2} direction='row' spacing={2} alignItems='center'>
					<Avatar
						sx={{
							borderRadius: 3,
							backgroundColor: color ? alpha(color, 0.08) : 'transparent',
						}}>
						{icon}
					</Avatar>
					<Typography
						sx={{
							display: '-webkit-box',
							overflow: 'hidden',
							WebkitBoxOrient: 'vertical',
							WebkitLineClamp: 1,
						}}
						variant='h6'>
						{task}
					</Typography>
				</Stack>
				<Stack flex={1} direction='row' spacing={1} alignItems='center'>
					<Typography variant='body2'>{status}</Typography>
				</Stack>
				<Stack
					flex={1}
					direction='row'
					spacing={1}
					alignItems='center'
					justifyContent='flex-end'>
					<ClockIcon color='orange' style={{ width: 24, height: 24 }} />
					<Typography variant='caption'>{deadline}</Typography>
				</Stack>
			</Box>
		</Box>
	);
};

export default Task;
