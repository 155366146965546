/** @format */

import {
  Avatar,
  Box,
  IconButton,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { CircleEllipsis } from 'lucide-react';
import { useAppSelector } from '@src/hooks/redux/useSelector';
import { RootState } from '@src/redux/store/Store';

const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const ChatHeader: React.FC = () => {
  const userInfo = useAppSelector((state: RootState) => state.session.data);
  const isAuth = useAppSelector((state: RootState) => state.session.isAuth);
  const theme = useTheme();
  return (
    <HeaderWrapper>
      <Stack
        direction='row'
        gap='8px'
        justifyContent='center'
        alignItems='center'
      >
        <Avatar
          alt='Remy Sharp'
          src='https://mui.com/static/images/avatar/1.jpg'
          sx={{
            width: 45,
            height: 45,
            border: `2px solid ${
              isAuth ? theme.palette.success.main : theme.palette.error.main
            }`,
          }}
        />
        <Stack direction='column' justifyContent='center' width='100%'>
          <Typography fontWeight='bold' variant='body1'>
            {userInfo?.firstName + ' ' + userInfo?.lastName}
          </Typography>
          <Stack direction='row' gap='5px' alignItems='center'>
            <Box
              sx={{
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: isAuth
                  ? theme.palette.success.main
                  : theme.palette.error.main,
              }}
            />
            <Typography
              color={
                isAuth ? theme.palette.success.main : theme.palette.error.main
              }
              variant='caption'
            >
              {isAuth ? 'En ligne' : 'Hors ligne'}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction='row' justifyContent='center' alignItems='center'>
        <IconButton>
          <CircleEllipsis size={18} />
        </IconButton>
      </Stack>
    </HeaderWrapper>
  );
};

export default ChatHeader;
