/**
 * @file Store.ts
 * @description This file sets up the Redux store for the application using Redux Toolkit's `configureStore` method.
 * It combines multiple slices of state including preferences, session, and company data, and integrates an API middleware.
 * 
 * @module Store
 */

 /**
	* @typedef {Object} RootState
	* @description Represents the root state of the Redux store.
	* It is inferred from the store's `getState` method.
	*/

 /**
	* @typedef {Function} AppDispatch
	* @description Represents the dispatch function of the Redux store.
	* It is inferred from the store's `dispatch` method.
	*/

 /**
	* @constant {Object} store
	* @description The Redux store configured with slices for preferences, session, company data, and API middleware.
	* It uses Redux Toolkit's `configureStore` method to combine reducers and apply middleware.
	*/
/** @format */

import { configureStore } from '@reduxjs/toolkit';
import PrefSlice from '../reducer/prefs/PrefSlice';
import SessionSlice from '../reducer/session/SessionSlice';
import { api } from './api';
import CompanySlice from '../reducer/company/CompanySlice';


export const store = configureStore({
	reducer: {
		prefs: PrefSlice,
		[api.reducerPath]: api.reducer,
		companies: CompanySlice,
		session: SessionSlice,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
