/** @format */

import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { RootState } from './Store';
import route from '@src/config/route.json';

/**
 * * API Object
 * * This is the base query for the API.
 * * It is used to prepare the headers for the request.
 * TODO: Add Credentials to the request.
 * * It is called before every request.
 */
const baseQuery = retry(
	fetchBaseQuery({
		baseUrl: route.baseUrl.replace(':domain', route.api.toString()),
		// credentials: 'include',
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).session.token;
			if (!headers.has('Content-Type')) {
				headers.set('Content-Type', 'application/json');
			}
			if (!headers.has('Authorization') && token) {
				headers.set('Authorization', `Bearer ${token}`);
			}
			return headers;
		},
	}),
	{ maxRetries: 5 },
);

const baseQueryWithDefaultParams = async (args: any, api: any, extraOptions: any) => {
	const state = api.getState() as RootState;
	const companyID = state.session.data?.id;
	if (typeof args === 'string') {
		args = { url: args };
	}
	if (args.url) {
		args.url = args.url.replace(/:id/, companyID?.toString() || '');
	}
	return baseQuery(args, api, extraOptions);
}

export const api = createApi({
	baseQuery: baseQueryWithDefaultParams,
	tagTypes: ['Employee', 'Teams'],
	refetchOnReconnect: true,
	refetchOnFocus: true,
	endpoints: () => ({}),
});
