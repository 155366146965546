/** @format */

import * as React from 'react';

import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@src/hooks/redux/useSelector';
import { selectLng, updateLng } from '@src/redux/reducer/prefs/PrefSlice';
import { useAppDispatch } from '@src/hooks/redux/useDispatch';

const General: React.FC = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const lng = useAppSelector(selectLng);
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState<string>(lng);

  const handleChange = (event: SelectChangeEvent) => {
    event.preventDefault();
    setValue(event.target.value);
    i18n.changeLanguage(event.target.value);
    dispatch(updateLng(event.target.value));
  };

  return (
    <Box
      width='100%'
      height='100%'
      display='flex'
      flexDirection='column'
      p={theme.spacing(1, 2)}
      gap={theme.spacing(2)}
    >
      <Typography variant='body2'>
        {t('app.settings.general.description')}
      </Typography>
      <Box
        display='flex'
        gap={theme.spacing(1)}
        width='100%'
        alignItems='center'
      >
        <Typography variant='body1'>{t('lng')}</Typography>
        <FormControl>
          <Select
            size='small'
            color='secondary'
            value={value}
            defaultValue={lng}
            id='lng'
            onChange={handleChange}
          >
            <MenuItem value='en'>🇬🇧 EN</MenuItem>
            <MenuItem value='fr'>🇫🇷 FR</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Typography fontSize={12}>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae
        accusantium fuga corporis cum totam officiis dolorum blanditiis minima
        maiores sapiente unde, eligendi obcaecati libero ex. Ipsum ullam
        repudiandae fugiat vitae!
      </Typography>
    </Box>
  );
};

export default General;
