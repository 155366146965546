/** @format */

import { Box, IconButton, Stack, styled, TextField } from '@mui/material';
import { Smile, SendHorizontal, Image, Mic } from 'lucide-react';
import { useState } from 'react';
import EmojiPicker, { EmojiClickData, Theme } from 'emoji-picker-react';

const ChatFooterWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	borderTop: `1px solid ${theme.palette.divider}`,
	gap: '20px',
	padding: '10px',
	marginTop: 'auto',
}));

const ChatFooter: React.FC = () => {
	const [openEmoji, setOpenEmoji] = useState<boolean>(false);
	const [text, setText] = useState<string>('');

	const handleEmoji = (emoji: EmojiClickData) => {
		setText(text + emoji.emoji);
	};

	const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setText(e.target.value);
	};

	return (
		<ChatFooterWrapper>
			<Stack direction='row' spacing={1}>
				<IconButton>
					<Image size={20} />
				</IconButton>
				<IconButton>
					<Mic size={20} />
				</IconButton>
			</Stack>
			<TextField
				sx={{
					'& .MuiInputBase-root': {
						borderRadius: 3,
						p: '8px',
						borderWidth: 0,
					},
				}}
				onChange={handleTextChange}
				size='small'
				value={text}
				placeholder='Ecrivez quelque chose...'
				color='secondary'
				fullWidth
			/>
			<Stack direction='row' spacing={1}>
				<IconButton onClick={() => setOpenEmoji(!openEmoji)}>
					<Smile size={20} />
					<Box position='relative'>
						<EmojiPicker
							style={{
								position: 'absolute',
								bottom: '40px',
								left: '0',
							}}
							theme={Theme.DARK}
							open={openEmoji}
							onEmojiClick={handleEmoji}
						/>
					</Box>
				</IconButton>
				<IconButton color='secondary'>
					<SendHorizontal size={25} />
				</IconButton>
			</Stack>
		</ChatFooterWrapper>
	);
};

export default ChatFooter;
