/** @format */

import React from 'react';
import {
  Menu,
  MenuItem,
  useTheme,
  ListItemText,
  ListItemIcon,
  Box,
  Typography,
  Divider,
  Stack,
  Avatar,
} from '@mui/material';
import { SignOut, UserCircle, UserCircleGear } from '@phosphor-icons/react';
import { useAppDispatch } from '@hooks/redux/useDispatch';
import { updateSession } from '@redux/reducer/session/SessionSlice';
import { clearSessionCookies } from '@cookie/Cookies';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@src/hooks/redux/useSelector';
interface ProfileMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  selected: string;
  onClose: () => void;
}

const ProfileMenu: React.FC<ProfileMenuProps> = (props: ProfileMenuProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const company = useAppSelector((state) => state.session.data);
  const user = useAppSelector((state) => state.session.user);

  const handleLogout = () => {
    dispatch(updateSession({ isAuth: false }));
    clearSessionCookies();
    props.onClose();
  };

  return (
    <Menu
      id='profile-menu'
      anchorEl={props.anchorEl}
      open={props.open}
      onClose={props.onClose}
      sx={{
        '& .MuiMenu-paper': {
          backgroundColor: theme.palette.primary.main,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 1,
          padding: '10px 20px',
        }}
      >
        <Avatar
          src={user?.avatarUrl || 'https://i.pravatar.cc/300'}
          alt={user?.firstName}
          sx={{
            width: 50,
            height: 50,
            margin: 'auto',
            borderRadius: '10px',
            border: `2px solid ${theme.palette.secondary.main}`,
          }}
        />
        <Stack direction='column' spacing={0.5} justifyContent='center'>
          <Typography
            fontWeight='bold'
            variant='h6'
          >{`${company?.firstName} ${company?.lastName}`}</Typography>
          <Typography color={theme.palette.text.secondary} variant='caption'>
            {company?.email}
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        <MenuItem disableRipple onClick={props.onClose}>
          <ListItemIcon>
            <UserCircle size={20} />
          </ListItemIcon>
          <ListItemText primary={t('app.options.account')} />
        </MenuItem>
        <MenuItem disableRipple onClick={props.onClose}>
          <ListItemIcon>
            <UserCircleGear size={20} />
          </ListItemIcon>
          <ListItemText primary={t('app.options.profile')} />
        </MenuItem>
        <Divider />
        <MenuItem disableRipple onClick={handleLogout}>
          <ListItemIcon>
            <SignOut size={20} color={theme.palette.error.main} />
          </ListItemIcon>
          <ListItemText
            color={theme.palette.error.main}
            primary={t('app.options.logout')}
          />
        </MenuItem>
      </Box>
    </Menu>
  );
};

export default ProfileMenu;
