/** @format */

import React from 'react';
import {
  Box,
  Avatar,
  Typography,
  Stack,
  Skeleton,
  IconButton,
  useTheme,
} from '@mui/material';
import { Ellipsis } from 'lucide-react';

export type MessageType = {
  id: number;
  user: string;
  avatar: string;
  date: Date;
  text: string;
  type: 'text' | 'file';
  fileSize?: string;
  own?: boolean;
};

const formatDate = (date: Date): string => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const isToday = date.toDateString() === today.toDateString();
  const isYesterday = date.toDateString() === yesterday.toDateString();
  const timeOptions = { hour: '2-digit', minute: '2-digit' };

  if (isToday) {
    return `Aujourd'hui ${date.toLocaleTimeString(
      undefined,
      timeOptions as any
    )}`;
  } else if (isYesterday) {
    return `Hier ${date.toLocaleTimeString(undefined, timeOptions as any)}`;
  } else {
    return `${date.getDate().toString().padStart(2, '0')}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}/${date.getFullYear()}`;
  }
};

interface ChatMessageProps {
  message?: MessageType;
}

const AuiChatItem: React.FC<ChatMessageProps> = ({ message }) => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    <Box
      sx={{
        display: 'flex',
        height: 'auto',
        width: '100%',
        justifyContent: 'space-between',
        padding: `8px ${theme.spacing(0.5)}`,
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 4,
          padding: '0 10px',
          gap: theme.spacing(1),
        }}
      >
        {message ? (
          <Avatar
            src={message.avatar}
            alt={message.user}
            sx={{ width: 50, height: 50 }}
          />
        ) : (
          <Skeleton variant='circular' width={50} height={50} />
        )}
        <Box
          sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '5px' }}
        >
          <Stack direction='row' alignItems='center' gap={1}>
            {message ? (
              <Typography fontWeight='bold' variant='body2'>
                {message.user}
              </Typography>
            ) : (
              <Skeleton variant='text' width={100} />
            )}
            {message ? (
              <Typography variant='caption' color='textSecondary'>
                {formatDate(message.date)}
              </Typography>
            ) : (
              <Skeleton variant='text' width={100} />
            )}
          </Stack>
          {message ? (
            <Typography
              variant='body2'
              sx={{
                wordWrap: 'break-word',
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
              }}
            >
              {message.text}
            </Typography>
          ) : (
            <Skeleton variant='text' width={200} />
          )}
        </Box>
      </Box>
      <Stack flex={0.1} justifyContent='flex-start'>
        {isHovered && (
          <IconButton>
            <Ellipsis size={15} />
          </IconButton>
        )}
      </Stack>
    </Box>
  );
};

export default AuiChatItem;
