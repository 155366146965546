/** @format */

import { createSlice, Slice } from '@reduxjs/toolkit';
import { StorageKey } from '@src/constants/Constant';
import { getCookie, setCookie } from '@src/cookie/Cookies';

type Prefs = {
	theme: {
		mode: string;
		accent: string;
	};
	lng: string;
	sidebar: boolean;
};

const initialState: Prefs = {
	theme: {
		mode: getCookie(StorageKey.MODE) || 'light',
		accent: getCookie(StorageKey.ACCENT) || '#8f5bcc',
	},
	lng: getCookie(StorageKey.LANGUAGE) || 'en',
	sidebar: getCookie(StorageKey.SIDEBAR) === 'true',
};

export const PrefSlice: Slice<Prefs> = createSlice({
	name: 'prefs',
	initialState,
	reducers: {
		updateTheme: (state, action) => {
			state.theme = action.payload;
			if (action.payload.mode) {
				setCookie(StorageKey.MODE, action.payload.mode);
			}
			if (action.payload.accent) {
				setCookie(StorageKey.ACCENT, action.payload.accent);
			}
		},
		updateLng: (state, action) => {
			state.lng = action.payload;
			setCookie(StorageKey.LANGUAGE, action.payload);
		},
		updateSidebar: (state) => {
			state.sidebar = !state.sidebar;
			setCookie(StorageKey.SIDEBAR, state.sidebar.toString());
		},
	},
});

export const selectTheme = (state: { prefs: Prefs }) => state.prefs.theme;
export const selectLng = (state: { prefs: Prefs }) => state.prefs.lng;
export const selectSidebar = (state: { prefs: Prefs }) => state.prefs.sidebar;

export const { updateTheme, updateLng, updateSidebar } = PrefSlice.actions;
export default PrefSlice.reducer;
