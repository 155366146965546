/** @format */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/en.json';
import fr from './locales/fr/fr.json';
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";

const resources = {
	en: {
		translation: en,
	},
	fr: {
		translation: fr,
	},
};

/**
 * @brief Initializes i18next with various plugins and configuration options.
 */
i18next
.use(detector) /**< Uses the language detector plugin. */
.use(backend) /**< Uses the backend plugin to load translations. */
.use(initReactI18next) /**< Integrates i18next with React. */
.init({
	resources, /**< Sets the translation resources. */
	fallbackLng: 'en', /**< Sets the fallback language to English. */
	interpolation: {
		escapeValue: false, /**< Disables escaping for values. */
	},
});

/**
 * @brief Sets the supported languages for i18next.
 */
i18next.languages = ['en', 'fr'];

/**
 * @brief Adds an event listener for language changes to update the document's language attribute.
 * @param lng The new language code.
 */
i18next.on('languageChanged', (lng) => {
	document.documentElement.lang = lng;
});

export default i18next;
