/** @format */

import { ColorModeContext, useMode } from '@config/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import * as React from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from '@router/Router';
import { IconContext } from '@phosphor-icons/react';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ModalProvider } from '@context/Modal';
import LoaderSpinner from '@pages/loader/Loader';
import '@src/lang/i18n';
import { getCookie } from './cookie/Cookies';
import { StorageKey } from './constants/Constant';

import './app.css'

function App() {
  const [theme, colorMode] = useMode();
  const lng = getCookie(StorageKey.LANGUAGE) || 'en';
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lng}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <IconContext.Provider
            value={{
              weight: 'regular',
              size: '1.8em',
            }}
          >
            <SnackbarProvider maxSnack={3}>
              <ModalProvider>
                <React.Suspense fallback={<LoaderSpinner />}>
                  <RouterProvider
                    router={router}
                    future={{
                      v7_startTransition: true,
                    }}
                  />
                </React.Suspense>
              </ModalProvider>
            </SnackbarProvider>
          </IconContext.Provider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </LocalizationProvider>
  );
}

export default App;
