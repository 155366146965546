/** @format */

import React, { useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  Stack,
  useTheme,
} from '@mui/material';

import {
  BellSimple as Notification,
  GearSix,
  CaretDown,
} from '@phosphor-icons/react';
import Setting from '@components/preferences/SettingModal';
import { AuiToolTip, AuiBadge } from '@components/AuiComponent/AuiComponent';
import ProfileMenu from '@components/profile/ProfileMenu';
import { useLocation } from 'react-router-dom';
import { getRouteName } from '@src/tools/Tools';
import AuiSearchBar from '../AuiSearchBar/AuiSearchBar';

const TopBar: React.FC = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const openProfile = Boolean(anchorEl);
  const location = useLocation();
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        border: 'none',
        height: '40px',
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderRadius: 0,
      }}
    >
      <AuiSearchBar name={getRouteName(location.pathname)} />
      <Box display='flex' justifyContent='center' alignItems='center' gap={2}>
        <Stack direction='row' spacing={2}>
          {/* <AuiToolTip title='Toggle notifications panel'> */}
          <IconButton disabled size='small' type='button'>
            <Badge
              variant='dot'
              color='error'
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Notification weight='regular' size={20} />
            </Badge>
          </IconButton>
          {/* </AuiToolTip> */}
          <AuiToolTip title='Toggle settings modal'>
            <IconButton size='small' type='button' onClick={handleDrawerOpen}>
              <GearSix size={20} weight='regular' />
            </IconButton>
          </AuiToolTip>
        </Stack>
        <Setting open={open} onClose={handleDrawerClose} />
        <Divider orientation='vertical' flexItem />
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='center'
          sx={{
            height: '100%',
            borderRadius: 3,
          }}
        >
          <Stack direction='row' spacing={1}>
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              height={40}
              width={40}
              borderRadius={2}
            >
              <AuiBadge
                overlap='circular'
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant='dot'
                color='success'
              >
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: 2,
                    border: `2px solid ${theme.palette.secondary.main}`,
                  }}
                  alt='profile'
                  src='https://mui.com/static/images/avatar/2.jpg'
                />
              </AuiBadge>
            </Box>
            <Stack alignItems='center' justifyContent='center'>
              <IconButton
                size='small'
                id='profile-icon'
                aria-controls={openProfile ? 'profile-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={openProfile ? 'true' : undefined}
                onClick={handleProfileMenuOpen}
              >
                <CaretDown size={16} />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
        <ProfileMenu
          anchorEl={anchorEl}
          open={openProfile}
          selected='Profile'
          onClose={handleProfileMenuClose}
        />
      </Box>
    </Box>
  );
};

export default TopBar;
