/** @format */

import React, { useState } from 'react';
import { Box, Typography, styled, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import Item from '../MenuItem/Item';
import { RoutePath } from '@constants/Constant';
import SidebarHeader from './SidebarHeader';
import SidebarFooter from './SidebarFooter';
import { useAppDispatch } from '@hooks/redux/useDispatch';
import { useAppSelector } from '@hooks/redux/useSelector';

import {
  Sidebar,
  Menu,
  menuClasses,
  MenuItemStyles,
  sidebarClasses,
} from 'react-pro-sidebar';

import { useLocation } from 'react-router-dom';
import {
  ChevronLeft,
  ChevronRight,
  LayoutDashboard,
  MessageCircle,
  MonitorSmartphone,
  ShieldHalf,
  UsersRound,
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import {
  selectSidebar,
  updateSidebar,
} from '@src/redux/reducer/prefs/PrefSlice';

const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  height: '100%',
}));

const StyledWrapper = styled(Box)(() => ({
  borderRadius: 0,
  padding: '4px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  gap: '20px',
}));

const ProSidebar: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();
  const [selected, setSelected] = useState('');
  const collapsed = useAppSelector(selectSidebar);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    switch (location.pathname) {
      case RoutePath.DASHBOARD:
        setSelected(t('app.sidebar.dashboard'));
        break;
      case RoutePath.MODULES:
        setSelected('Mes Modules');
        break;
      case RoutePath.PLANNING:
        setSelected('Planning');
        break;
      case RoutePath.PRESENCES:
        setSelected(t('app.sidebar.team'));
        break;
      // case RoutePath.MODULES_PATH:
      // 	setSelected(t('app.sidebar.modules'));
      // 	break;
      case RoutePath.EMPLOYEES:
        setSelected(t('app.sidebar.employees'));
        break;
      case RoutePath.CHAT:
        setSelected(t('app.sidebar.chat'));
        break;
      case RoutePath.CUSTOM:
        setSelected(t('app.sidebar.custom'));
        break;
      default:
        setSelected('');
    }
  }, [location.pathname, t]);

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: '13px',
      fontWeight: 400,
      [`.${menuClasses.active}`]: {
        color: theme.palette.secondary.main,
      },
    },
    button: {
      borderRadius: '10px',
      // [`.${menuClasses.disabled}`]: {
      //   color: theme.palette.text.disabled,
      // },
      [`.${menuClasses.active}`]: {
        backgroundColor: 'transparent',
      },
      '&:hover': {
        backgroundColor: alpha(theme.palette.secondary.main, 0.2),
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <StyledContainer>
      <Box
        onClick={() => dispatch(updateSidebar(collapsed))}
        sx={{
          position: 'absolute',
          top: '50%',
          right: '-13px',
          transform: 'translateY(-50%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '16px',
          overflow: 'hidden',
          height: '100px',
          borderRadius: '0 20px 20px 0',
          border: `2px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.primary.main,
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.action.hover,
          },
        }}
      >
        {collapsed ? <ChevronRight size={15} /> : <ChevronLeft size={15} />}
      </Box>
      <Sidebar
        width='2OOpx'
        collapsed={collapsed}
        breakPoint='md'
        rootStyles={{
          height: '100%',
          [`.${sidebarClasses.container}`]: {
            backgroundColor: theme.palette.primary.main,
          },
          borderColor: theme.palette.divider,
        }}
      >
        <StyledWrapper>
          <SidebarHeader />
          <Box
            sx={{
              flex: 2,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Box sx={{ padding: '0 24px', marginBottom: '8px' }}>
              <Typography
                variant='body2'
                fontWeight={600}
                style={{
                  opacity: collapsed ? 0 : 1,
                  letterSpacing: '0.5px',
                }}
              >
                {t('app.sidebar.modules')}
              </Typography>
            </Box>
            <Menu menuItemStyles={menuItemStyles}>
              <Item
                icon={<LayoutDashboard />}
                title={t('app.sidebar.dashboard')}
                to={RoutePath.DASHBOARD}
                selected={selected}
                onClick={() => setSelected(t('app.sidebar.dashboard'))}
              />
              <Item
                icon={<MessageCircle />}
                title={t('app.sidebar.chat')}
                to={RoutePath.CHAT}
                selected={selected}
                onClick={() => setSelected(t('app.sidebar.chat'))}
              />
              <Item
                icon={<ShieldHalf />}
                title={t('app.sidebar.team')}
                to={RoutePath.PRESENCES}
                selected={selected}
                onClick={() => setSelected(t('app.sidebar.team'))}
              />
            </Menu>
            <>
              <Box
                sx={{
                  padding: '0 24px',
                  marginBottom: '8px',
                  marginTop: '32px',
                }}
              >
                <Typography
                  variant='body2'
                  fontWeight={600}
                  style={{
                    opacity: collapsed ? 0 : 1,
                    letterSpacing: '0.5px',
                  }}
                >
                  {t('app.sidebar.company')}
                </Typography>
              </Box>
              <Menu menuItemStyles={menuItemStyles}>
                <Item
                  icon={<UsersRound />}
                  title={t('app.sidebar.employees')}
                  to={RoutePath.EMPLOYEES}
                  selected={selected}
                  onClick={() => setSelected(t('app.sidebar.employees'))}
                />
                <Item
                  icon={<MonitorSmartphone />}
                  title={t('app.sidebar.custom')}
                  to={RoutePath.CUSTOM}
                  selected={selected}
                  onClick={() => setSelected(t('app.sidebar.custom'))}
                />
              </Menu>
            </>
          </Box>
          <SidebarFooter collapsed={collapsed} />
        </StyledWrapper>
      </Sidebar>
    </StyledContainer>
  );
};

export default ProSidebar;
