/** @format */
import Cookies from 'js-cookie';
import { StorageKey } from '@constants/Constant';
import { CookieOptions } from '@src/types/types';

const setCookie = (key: string, value: string, options?: CookieOptions) => {
	Cookies.set(
		key,
		value,
		(options = {
			expires: 7,
			secure: true,
			sameSite: 'strict',
		}),
	);
};

const getCookie = (key: string) => {
	return Cookies.get(key);
};

const saveSessionToCookies = (sessionData: any) => {
	Cookies.set(StorageKey.SESSION, JSON.stringify(sessionData), {
		expires: 7,
		secure: true,
		sameSite: 'strict',
	});
};

const clearCookie = (key: string) => {
	Cookies.remove(key);
};

const getSessionFromCookies = () => {
	const sessionData = Cookies.get(StorageKey.SESSION);
	return sessionData ? JSON.parse(sessionData) : null;
};

const clearSessionCookies = () => {
	Cookies.remove(StorageKey.SESSION);
};

export {
	saveSessionToCookies,
	getSessionFromCookies,
	clearSessionCookies,
	setCookie,
	getCookie,
	clearCookie,
};
