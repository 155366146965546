/** @format */

import {
  Box,
  CircularProgress,
  MenuItem,
  Stack,
  styled,
  Typography,
  useTheme,
  Breadcrumbs,
  Link,
} from '@mui/material';
import React from 'react';
import AuiButton from '@components/AuiButton/AuiButton';
import { SubmitHandler, useForm } from 'react-hook-form';
import { EmployeeType, Member } from '@type/types';
import { EmployeeSchema } from '@type/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import AuiInput from '@components/AuiInput/AuiInput';
import AuiSelect from '@components/AuiSelect/AuiSelect';
import '@styles/form.scss';
import AuiAvatarPicker from '@components/AuiPicker/AuiAvatarPicker';
import { useAddEmployeeMutation } from '@src/services/users/UserSlice';
import { UserRoundPlus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '@src/constants/Constant';
import { AuiBox } from '@src/components/AuiComponent/AuiComponent';
import { useTranslation } from 'react-i18next';

const RootWrapper = styled(AuiBox)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const FormContainer = styled(AuiBox)(({ theme }) => ({
  height: 'max-content',
  width: '100%',
  flexDirection: 'column',
  gap: 4,
  alignItems: 'center',
  display: 'flex',
}));

const CreateForm: React.FC = () => {
  const theme = useTheme();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<EmployeeType>({
    resolver: zodResolver(EmployeeSchema),
  });
  const [addEmployee, { isLoading }] = useAddEmployeeMutation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const submit: SubmitHandler<EmployeeType> = async (data: EmployeeType) => {
    try {
      const body = {
        display_name: `${data.firstName} ${data.lastName}`,
        ...data,
      } as Member;
      const toto = {
        password: 'securepassword',
        ...body,
      };
      await addEmployee({ employee: toto }).unwrap();
      navigate(RoutePath.EMPLOYEES);
    } catch (error) {
      console.error('Error adding employee:', error);
    }
  };

  return (
    <RootWrapper>
      <Stack direction='row' padding={'20px'}>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link underline='hover' color='inherit' href={RoutePath.EMPLOYEES}>
            Employees
          </Link>
          <Typography color='textPrimary'>Add</Typography>
        </Breadcrumbs>
      </Stack>
      <FormContainer>
        <form
          onSubmit={handleSubmit(submit)}
          id='employee-form'
          className='aui-form'
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '20px 40px',
              gap: 4,
              width: '100%',
              maxWidth: '700px',
            }}
          >
            <Stack direction='row' spacing={2} alignItems='center'>
              <UserRoundPlus size='3em' color={theme.palette.secondary.main} />
              <Stack direction='column' spacing={1}>
                <Typography variant='h4' gutterBottom fontWeight='bold'>
                  {t('app.employee.add.title')}
                </Typography>
                <Typography variant='body1' color='textSecondary'>
                  {t('app.employee.add.description')}
                </Typography>
              </Stack>
            </Stack>
            <AuiAvatarPicker
              sizes={120}
              onImageChange={(value) => {
                setValue('avatarUrl', value, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
            />
            <Stack direction='row' spacing={theme.spacing(1)}>
              <AuiInput
                required
                name='lastName'
                type='text'
                label={t('form.fields.lastName.label')}
                placeholder={t('form.fields.lastName.placeholder')}
                control={control}
                error={errors.lastName}
              />
              <AuiInput
                required
                name='firstName'
                type='text'
                label={t('form.fields.firstName.label')}
                placeholder={t('form.fields.firstName.placeholder')}
                control={control}
                error={errors.firstName}
              />
            </Stack>
            <Stack direction='row' spacing={theme.spacing(1)}>
              <AuiInput
                required
                name='email'
                type='email'
                label={t('form.fields.email.label')}
                placeholder={t('form.fields.email.placeholder')}
                control={control}
                error={errors.email}
              />
              <AuiInput
                required
                name='phone'
                type='tel'
                label={t('form.fields.phone.label')}
                placeholder={t('form.fields.phone.placeholder')}
                control={control}
                error={errors.phone}
              />
            </Stack>
            <Stack direction='row' spacing={theme.spacing(1)}>
              <AuiSelect
                name='role'
                label={t('form.fields.post.label')}
                defaultValue='Employee'
                control={control}
              >
                <MenuItem value='Employee'>{t('app.role.employee')}</MenuItem>
                <MenuItem value='Intern'>{t('app.role.intern')}</MenuItem>
                <MenuItem value='Manager'>{t('app.role.manager')}</MenuItem>
                <MenuItem value='Secretary'>{t('app.role.secretary')}</MenuItem>
              </AuiSelect>
              <AuiInput
                required
                name='birthDate'
                type='date'
                label={t('form.fields.birthDate.label')}
                defaultValue={new Date().toISOString().split('T')[0]}
                control={control}
                error={errors.birthDate}
              />
            </Stack>
            <AuiInput
              name='address'
              multiline
              rows={3}
              type='text'
              label={t('form.fields.address.label')}
              placeholder={t('form.fields.address.placeholder')}
              control={control}
              error={errors.address}
            />
            <Stack
              direction='row'
              spacing={theme.spacing(1)}
              justifyContent='flex-end'
            >
              <AuiButton
                variant='outlined'
                color='secondary'
                onClick={() => navigate(RoutePath.EMPLOYEES)}
                sx={{
                  textTransform: 'none',
                }}
              >
                {t('form.buttons.cancel')}
              </AuiButton>
              <AuiButton
                type='submit'
                variant='contained'
                disabled={isLoading}
                endIcon={isLoading && <CircularProgress size={20} />}
                color='secondary'
                sx={{
                  textTransform: 'none',
                }}
              >
                {t('form.buttons.submit')}
              </AuiButton>
            </Stack>
          </Box>
        </form>
      </FormContainer>
    </RootWrapper>
  );
};

export default CreateForm;
