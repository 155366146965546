/** @format */

import { createContext, useMemo, useState } from 'react';
import { createTheme, PaletteMode, Theme, ThemeOptions, useMediaQuery } from '@mui/material';
import { useAppSelector } from '@src/hooks/redux/useSelector';
import { useAppDispatch } from '@src/hooks/redux/useDispatch';
import { selectTheme, updateTheme } from '@src/redux/reducer/prefs/PrefSlice';
import { getCookie } from '@src/cookie/Cookies';
import { StorageKey } from '@src/constants/Constant';
import { frFR as coreFr, enUS as coreEn } from '@mui/material/locale';
import { frFR as DateFr, enUS as DateEn } from '@mui/x-date-pickers/locales';
import { frFR as GridFr, enUS as GridEn } from '@mui/x-data-grid/locales';
import color from "@config/color.json";

export interface TokensProps {
	primary: Record<number, string>;
	secondary: Record<number, string>;
}

export const tokens = (mode: PaletteMode): TokensProps => ({
	...(mode === 'dark'
		? {
				primary: {
					100: '#d2d2d3',
					200: '#a4a4a8',
					300: '#77777c',
					400: '#494951',
					500: '#1c1c25',
					600: '#16161e',
					700: '#111116',
					800: '#0b0b0f',
					900: '#060607',
				},
				secondary: {
					100: '#e9def5',
					200: '#d2bdeb',
					300: '#bc9de0',
					400: '#a57cd6',
					500: '#8f5bcc',
					600: '#7249a3',
					700: '#56377a',
					800: '#392452',
					900: '#1d1229',
				},
		  }
		: {
				primary: {
					100: '#060607',
					200: '#0b0b0f',
					300: '#111116',
					400: '#16161e',
					500: '#1c1c25',
					600: '#494951',
					700: '#77777c',
					800: '#a4a4a8',
					900: '#d2d2d3',
				},
				secondary: {
					100: '#1d1229',
					200: '#392452',
					300: '#56377a',
					400: '#7249a3',
					500: '#8f5bcc',
					600: '#a57cd6',
					700: '#bc9de0',
					800: '#d2bdeb',
					900: '#e9def5',
				},
		  }),
});

export const themeSettings = (mode: PaletteMode, accent: string): ThemeOptions => {
	return {
		palette: {
			mode: mode,
			...(mode === 'dark'
				? {
						primary: {
							main: color.theme.dark.primary,
							light: color.theme.dark.background.paper
						},
						secondary: {
							main: accent,
						},
						background: {
							default: color.theme.dark.background.default,
							paper: color.theme.dark.background.paper
						},
						divider: '#2b2b35',
				  }
				: {
						primary: {
							main: color.theme.light.primary,
						},
						secondary: {
							main: accent,
						},
						background: {
							default: color.theme.light.background.default,
							paper: color.theme.light.background.paper
						},
						divider: '#e0e0e0',
				  }),
		},
		shape: {
			borderRadius: 4,
		},
		breakpoints: {
			values: {
			  mobile: 0,
			  tablet: 640,
			  laptop: 1024,
			  desktop: 1200,
			},
		},
		spacing: 15,
		components: {
			MuiMenuList: {
				styleOverrides: {
					root: {
						borderRadius: 3,
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 5,
					},
				},
			},
			MuiCard: {
				styleOverrides: {
					root: {
						borderRadius: 3,
					},
				},
			},
			MuiAutocomplete: {
				styleOverrides: {
					root: {
						borderRadius: 3,
					},
				},
			},
			MuiInputBase: {
				styleOverrides: {
					root: {
						borderRadius: 3,
					},
				},
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: 5
					},
				},
			},
			MuiSelect: {
				styleOverrides: {
					root: {
						borderRadius: 3,
					},
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						borderRadius: 3,
					},
				},
			},
			MuiModal: {
				styleOverrides: {
					root: {
						borderRadius: 3,
					},
				},
			},
			MuiGrid: {
				styleOverrides: {
					root: {
						margin: 0,
					},
				},
			},
			MuiBadge: {
				styleOverrides: {
					root: {
						borderRadius: 3,
					},
				},
			},
			MuiTooltip: {
				styleOverrides: {
					tooltip: {
						borderRadius: 3,
					},
				},
			},
			MuiList: {
				styleOverrides: {
					root: {
						borderRadius: 3,
						backgroundColor: mode === 'dark' ? '#1c1c25' : '#fefcf3',
						border: mode === 'dark' ? '1px solid #2b2b35' : '1px solid #e0e0e0',
					},
				},
			},
			MuiPopper: {
				styleOverrides: {
					root: {
						borderRadius: 50,
					},
				},
			},
			MuiPopover: {
				styleOverrides: {
					root: {
						borderRadius: 3,
					},
					paper: {
						borderRadius: 3,
					},
				},
			},
			MuiCheckbox: {
				styleOverrides: {
					root: {
						borderRadius: 3,
					},
				},
			},
			MuiAccordion: {
				styleOverrides: {
					root: {
						borderRadius: 3,
					},
				},
			},
			MuiDialog: {
				styleOverrides: {
					paper: {
						borderRadius: 3,
					},
				},
			},
			MuiSnackbar: {
				styleOverrides: {
					root: {
						borderRadius: 3,
					},
				},
			},
			MuiSnackbarContent: {
				styleOverrides: {
					root: {
						borderRadius: 3,
					},
				},
			},
			MuiAlert: {
				styleOverrides: {
					root: {
						borderRadius: 3,
					},
				},
			},
			MuiAvatar: {
				styleOverrides: {
					root: {
						objectFit: 'cover',
					},
				},
			},
		},
		transitions: {
			easing: {
				easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
				easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
				easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
				sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
			},
		},
		typography: {
			fontFamily: ['Inter', 'sans-serif'].join(','),
			fontSize: 12,
			h1: {
				fontFamily: ['Inter', 'sans-serif'].join(','),
				fontSize: 40,
			},
			h2: {
				fontFamily: ['Inter', 'sans-serif'].join(','),
				fontSize: 32,
			},
			h3: {
				fontFamily: ['Inter', 'sans-serif'].join(','),
				fontSize: 24,
			},
			h4: {
				fontFamily: ['Inter', 'sans-serif'].join(','),
				fontSize: 20,
			},
			h5: {
				fontFamily: ['Inter', 'sans-serif'].join(','),
				fontSize: 16,
			},
			h6: {
				fontFamily: ['Inter', 'sans-serif'].join(','),
				fontSize: 14,
			},
			body1: {
				fontFamily: ['Inter', 'sans-serif'].join(','),
				fontSize: 13,
			},
		},
	};
};

export interface ColorModeContextProps {
	toggleMode: (mode: ExtendedPaletteMode) => void;
	changeAccent: (color: string) => void;
}

export type ExtendedPaletteMode = PaletteMode | 'system';

export const ColorModeContext = createContext<ColorModeContextProps>({
	toggleMode: (mode: ExtendedPaletteMode) => {},
	changeAccent: (color: string) => {},
});
export const useMode = (): [Theme, ColorModeContextProps] => {
	const accent = useAppSelector(selectTheme).accent;
	const defaultMode = useAppSelector(selectTheme).mode;
	const dispatch = useAppDispatch();
	const lgn = getCookie(StorageKey.LANGUAGE) || 'en';
	const systemPrefersDark = useMediaQuery('(prefers-color-scheme: dark)');
  
	const [mode, setMode] = useState<ExtendedPaletteMode>(defaultMode as ExtendedPaletteMode);
	const [accentColor, setAccent] = useState<string>(accent);
  
	const resolvedMode: PaletteMode = mode === 'system' ? (systemPrefersDark ? 'dark' : 'light') : mode;
  
	const colorMode = useMemo(
	  () => ({
		toggleMode: (newMode: ExtendedPaletteMode) => {
		  const finalMode: PaletteMode = newMode === 'system' ? (systemPrefersDark ? 'dark' : 'light') : newMode;
		  setMode(newMode);
		  dispatch(updateTheme({ mode: finalMode }));
		},
		changeAccent: (color: string) => {
		  setAccent(color);
		  dispatch(updateTheme({ accent: color }));
		},
	  }),
	  [dispatch, systemPrefersDark],
	);
  
	const theme = useMemo(
	  () =>
		createTheme(
		  themeSettings(resolvedMode, accentColor),
		  lgn === 'en' ? coreEn : coreFr,
		  lgn === 'en' ? DateEn : DateFr,
		  lgn === 'en' ? GridEn : GridFr,
		),
	  [resolvedMode, accentColor, lgn],
	);
  
	return [theme, colorMode];
  };