/** @format */

import { RootState } from '@redux/store/Store';
import { Company, requestState } from '@type/types';
import { createEntityAdapter, createSlice, Slice } from '@reduxjs/toolkit';

const companiesAdapter = createEntityAdapter({
	selectId: (company: Company) => company.id,
	sortComparer: (a, b) => a.companyName.localeCompare(b.companyName),
});

export const companySlice: Slice<any> = createSlice({
	name: 'companies',
	initialState: companiesAdapter.getInitialState({
		status: 'idle' as requestState,
		error: undefined as string | undefined,
	}),
	reducers: {
		createCompany: (state, action) => {
			companiesAdapter.addOne(state, action.payload);
		},
		removeCompany: (state, action) => {
			companiesAdapter.removeOne(state, action.payload);
		},
	},
});

export const companyStatus = (state: RootState) => state.companies.status;
export const companyError = (state: RootState) => state.companies.error;

export const { createCompany, removeCompany } = companySlice.actions;
export default companySlice.reducer;

export const {
	selectAll: selectAllCompanies,
	selectById: selectCompanyById,
	selectIds: selectCompanyIds,
} = companiesAdapter.getSelectors((state: RootState) => state.companies);
