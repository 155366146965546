/** @format */

import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useAppSelector } from '@hooks/redux/useSelector';
import { RootState } from '@redux/store/Store';
import PerformanceChart from './component/Graph';
import TaskHistory from '@src/pages/dashboard/component/taskHistory';
import WelcomeHeader from '@src/pages/dashboard/component/WelcomeHeader';
import Profile from '@src/pages/dashboard/component/Profile';
import { AuiBox } from '@src/components/AuiComponent/AuiComponent';

const DashBoard: React.FC = () => {
  const theme = useTheme();
  const session = useAppSelector((state: RootState) => state.session);
  const responsive = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    <AuiBox
      sx={{
        display: 'flex',
        height: '100%',
        width: '100%',
        gap: '60px',
        padding: theme.spacing(2),
        paddingBottom: 0,
        flexDirection: responsive ? 'column' : 'row',
      }}
    >
      <Box
        sx={{
          flex: 3,
          height: '100%',
          width: '100%',
          gap: '20px',
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <WelcomeHeader user={session.user} />
        <PerformanceChart />
        <TaskHistory />
      </Box>
      <Box
        sx={{
          flex: 1.5,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          borderLeft: `1px solid ${theme.palette.divider}`,
          padding: '10px',
        }}
      >
        <Profile user={session} />
      </Box>
    </AuiBox>
  );
};

export default DashBoard;
