import React from 'react';
import { Box, useTheme, Typography, CircularProgress } from '@mui/material';
import { CloudUpload } from 'lucide-react';
import AuiButton from '../AuiButton/AuiButton';

interface AuiAvatarPickerProps {
  defaultValue?: string;
  sizes?: number;
  error?: any;
  onImageChange: (value: string) => void;
}

const AuiAvatarPicker: React.FC<AuiAvatarPickerProps> = ({
  defaultValue = '',
  sizes,
  error,
  onImageChange,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = React.useState(false);
  const [image, setImage] = React.useState<string | null>(defaultValue ?? null);
  const hasError = Boolean(error);

  React.useEffect(() => {
    setImage(defaultValue);
  }, [defaultValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        setImage(result);
        setLoading(false);
        onImageChange(result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      border={`2px dashed ${theme.palette.divider}`}
      width='100%'
      borderRadius='10px'
      overflow='hidden'
      height={sizes ?? '150px'}
      position='relative'
      bgcolor={theme.palette.primary.main}
      boxShadow={hasError ? `0 0 0 2px ${theme.palette.error.main}` : 'none'}
    >
      <input
        type='file'
        accept='image/*'
        style={{ display: 'none' }}
        id='icon-button-file'
        onChange={handleChange}
      />
      {loading ? (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : image ? (
        <img
          src={image}
          alt='avatar'
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      ) : (
        <CloudUpload size={30} color={theme.palette.text.secondary} />
      )}
      <label
        htmlFor='icon-button-file'
        style={{
          position: 'absolute',
          top: '80%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <AuiButton
          component='span'
          aria-label='upload picture'
          variant='outlined'
          color='secondary'
          sx={{
            fontWeight: 'bold',
            textTransform: 'none',
          }}
        >
          Upload
        </AuiButton>
      </label>
      {hasError && (
        <Typography variant='caption' color='error'>
          {error?.message}
        </Typography>
      )}
    </Box>
  );
};

export default AuiAvatarPicker;
