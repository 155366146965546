/** @format */
import { Member } from '@type/types';

/**
 * Filters members whose birthdays are within a specified number of days from the current date.
 * The filtered members are sorted by the closeness of their birthdays to the current date.
 *
 * @param {Member[]} members - The array of members to filter.
 * @param {number} daysThreshold - The number of days within which a member's birthday should be to be included in the result.
 * @returns {Member[]} - The array of members whose birthdays are within the specified number of days from the current date, sorted by the closeness of their birthdays.
 */
/**
 * Filters a list of members to find those whose birthdays are within a specified number of days from the current date.
 *
 * @param members - An array of `Member` objects to filter.
 * @param daysThreshold - The number of days within which a member's birthday should be to be included in the result.
 * @returns An array of `Member` objects whose birthdays are within the specified number of days from the current date.
 */
export function filterMembersByCloseBirthday(members: Member[], daysThreshold: number): Member[] {
	const currentDate = new Date();
	const filteredMembers = members.filter((member) => {
		if (member.birthDate) {
			const birthday = new Date(member.birthDate);
			const currentYearBirthday = new Date(
				currentDate.getFullYear(),
				birthday.getMonth(),
				birthday.getDate(),
			);
			const daysDiff = Math.ceil(
				(currentYearBirthday.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24),
			);
			return daysDiff <= daysThreshold;
		}
		return false;
	});
	filteredMembers.sort((a, b) => {
		const aBirthday = new Date(a.birthDate!);
		const bBirthday = new Date(b.birthDate!);
		const aDiff = Math.abs(
			currentDate.getTime() -
				new Date(currentDate.getFullYear(), aBirthday.getMonth(), aBirthday.getDate()).getTime(),
		);
		const bDiff = Math.abs(
			currentDate.getTime() -
				new Date(currentDate.getFullYear(), bBirthday.getMonth(), bBirthday.getDate()).getTime(),
		);
		return aDiff - bDiff;
	});
	return filteredMembers;
}

export function randomColor() {
	const letters = '0123456789ABCDEF';
	let color = '#';
	for (let i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}
	return color;
}

/**
 * Calculates the number of days left until the next occurrence of a given birthday.
 *
 * @param birthDate - The birth date in string format (e.g., "YYYY-MM-DD").
 * @returns The number of days left until the next birthday, or `null` if the date is invalid.
 */
/**
 * Calculates the number of days left until the next birthday based on the provided birth date.
 *
 * @param birthDate - A string representing the birth date in a format recognized by the `Date` constructor.
 * @returns The number of days left until the next birthday. Returns `0` if the birthday is today or within the last two days.
 *          Returns `null` if the birth date is invalid or if the birthday has already passed this year.
 *
 * @example
 * ```typescript
 * const daysLeft = leftdaysToBirthday('1990-12-25');
 * console.log(daysLeft); // Output will vary depending on the current date
 * ```
 */
export function leftdaysToBirthday(birthDate: string): number | null {
	const currentDate = new Date();
	const birthday = new Date(birthDate);
	const currentYearBirthday = new Date(
		currentDate.getFullYear(),
		birthday.getMonth(),
		birthday.getDate(),
	);

	let daysDiff = Math.ceil(
		(currentYearBirthday.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24),
	);

	if (daysDiff < -2) {
		currentYearBirthday.setFullYear(currentDate.getFullYear() + 1);
		daysDiff = Math.ceil(
			(currentYearBirthday.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24),
		);
	}

	if (daysDiff >= -2 && daysDiff <= 0) {
		return 0;
	}

	return daysDiff > 0 ? daysDiff : null;
}

/**
 * Extracts the last segment of the route from the given pathname and capitalizes the first letter.
 *
 * @param {string} pathname - The current pathname (e.g., "/users/employees")
 * @returns {string} - The capitalized route name (e.g., "Employees")
 */
export const getRouteName = (pathname: string): string => {
	const segments = pathname.split('/');

	const routeName = segments.filter((segment) => segment !== '').pop() || '';

	return routeName.charAt(0).toUpperCase() + routeName.slice(1);
};

export function getUpcomingBirthdays(employees: Member[], dayThreshold: number): Member[] {
	return employees
		.map((employee) => ({
			...employee,
			daysLeft: leftdaysToBirthday(employee.birthDate!),
		}))
		.filter((employee) => employee.daysLeft !== null && employee.daysLeft <= dayThreshold)
		.sort((a, b) => (a.daysLeft as number) - (b.daysLeft as number));
}

/**
 * Adjusts the intensity of a hex color.
 * @param color - The hex color code (e.g., "#RRGGBB").
 * @param factor - The factor by which to adjust the intensity (e.g., 0.5 to darken, 1.5 to lighten).
 * @returns The adjusted hex color code.
 */
export function adjustColorIntensity(color: string, factor: number): string {
	if (color.startsWith('#')) {
		color = color.slice(1);
	}
	let r = parseInt(color.slice(0, 2), 16);
	let g = parseInt(color.slice(2, 4), 16);
	let b = parseInt(color.slice(4, 6), 16);

	r = Math.min(255, Math.max(0, Math.round(r * factor)));
	g = Math.min(255, Math.max(0, Math.round(g * factor)));
	b = Math.min(255, Math.max(0, Math.round(b * factor)));

	const toHex = (n: number) => n.toString(16).padStart(2, '0');

	return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export function generateId() {
	return Math.floor(Math.random() * 10000000000);
}

export function randomInt(min: number, max: number) {
	return Math.floor(Math.random() * (max - min + 1) + min);
}
