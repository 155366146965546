/** @format */

import React from 'react';
import {
  alpha,
  Grid2,
  styled,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ProModal from '../AuiModal/AuiModal';
import Appearance from './appearance/Appearance';
import Account from './account/Account';
import General from './general/General';

import { TShirt, UserGear, Wrench } from '@phosphor-icons/react';
import { SettingHeader } from './Header';
import { useTranslation } from 'react-i18next';
import { getCookie } from '@src/cookie/Cookies';
import { StorageKey } from '@src/constants/Constant';
import { ColorModeContext, ExtendedPaletteMode } from '@src/config/theme';

interface AntTabProps {
  label: string;
  icon: JSX.Element;
  size?: 'small' | 'medium';
}

const AntTab = styled((props: AntTabProps) => (
  <Tab iconPosition='start' {...props} />
))(({ theme, size = 'medium' }) => ({
  textTransform: 'none',
  justifyContent: size === 'small' ? 'center' : 'flex-start',
  '&.Mui-selected': {
    color: 'secondary',
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  },
}));

interface SettingProps {
  open: boolean;
  onClose: () => void;
}

const Setting: React.FC<SettingProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selected, setSelected] = React.useState<number>(0);
  const medium = useMediaQuery(theme.breakpoints.down('tablet'));
  const colorMode = React.useContext(ColorModeContext);
  const activeMode = getCookie(StorageKey.MODE) as ExtendedPaletteMode;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelected(newValue);
  };

  const handleModeToggle = (mode: ExtendedPaletteMode) => {
    colorMode.toggleMode(mode);
  };

  const tabs = [
    {
      icon: <TShirt size={25} />,
      label: t('app.settings.appearance.title'),
      element: (
        <Appearance
          activeMode={activeMode}
          medium={medium}
          handleModeToggle={handleModeToggle}
        />
      ),
    },
    {
      icon: <Wrench size={25} />,
      label: t('app.settings.general.title'),
      element: <General />,
    },
    {
      icon: <UserGear size={25} />,
      label: t('app.settings.account.title'),
      element: <Account />,
    },
  ];

  return (
    <ProModal
      responsive={medium}
      open={open}
      onClose={onClose}
      height={medium ? '100%' : '60%'}
      width={medium ? '100%' : '45%'}
    >
      <Grid2 container height={'100%'}>
        <Grid2
          size={{ mobile: 3, tablet: 3 }}
          borderRight={`1px solid ${theme.palette.divider}`}
          sx={{
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Tabs
            orientation='vertical'
            variant='scrollable'
            textColor='secondary'
            value={selected}
            TabIndicatorProps={{
              sx: {
                width: 0,
              },
            }}
            onChange={handleChange}
          >
            {tabs.map((tab, index) => (
              <AntTab
                key={index}
                label={tab.label}
                icon={tab.icon}
                size={medium ? 'small' : 'medium'}
              />
            ))}
          </Tabs>
        </Grid2>
        <Grid2 size={{ mobile: 9, tablet: 9 }}>
          {tabs.map((tab, index) => (
            <div
              key={index}
              role='tabpanel'
              hidden={selected !== index}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}
            >
              <SettingHeader key={index} title={tab.label} onClose={onClose} />
              {selected === index && tab.element}
            </div>
          ))}
        </Grid2>
      </Grid2>
    </ProModal>
  );
};

export default Setting;
