import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';
import AuiModal from '@components/AuiModal/AuiModal';
import { Box } from '@mui/material';
import { generateId } from '@tools/Tools';

type ModalContent = {
  id: number;
  content: ReactNode;
  header?: ReactNode;
};

type ModalContextType = {
  openModal: (content: ReactNode, header?: ReactNode, id?: number) => void;
  closeModal: (id: number) => void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [modals, setModals] = useState<ModalContent[]>([]);

  const openModal = useCallback(
    (content: ReactNode, header?: ReactNode, id?: number) => {
      const modalId = id || generateId();
      setModals((modals) => [...modals, { id: modalId, content, header }]);
    },
    []
  );

  const closeModal = useCallback((id: number) => {
    setModals((modals) => modals.filter((modal) => modal.id !== id));
  }, []);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modals.map((modal) => (
        <AuiModal
          key={modal.id}
          open={true}
          component={modal.header}
          onClose={() => closeModal(modal.id)}
        >
          <Box height='100%' width='100%' display='flex' flexDirection='column'>
            {modal.content}
          </Box>
        </AuiModal>
      ))}
    </ModalContext.Provider>
  );
};
