/** @format */

import React from 'react';
import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import logo from '@assets/images/hello.png';
import { Member } from '@src/types/types';
import { AuiSkeleton } from '@src/components/AuiComponent/AuiComponent';

interface Props {
  user?: Member;
}

const WelcomeHeader: React.FC<Props> = ({ user }) => {
  const theme = useTheme();

  theme.typography.h3 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.up('tablet')]: {
      fontSize: '2rem',
    },
  };
  return (
    <Box
      width='100%'
      height='500px'
      display='flex'
      alignItems='center'
      justifyContent='center'
      p={2}
      borderRadius='20px'
      sx={{
        transition: 'all 0.3s',
        position: 'relative',
        backgroundImage: `linear-gradient(to right, ${theme.palette.secondary.main}, ${theme.palette.secondary.light})`,
      }}
    >
      <Stack
        sx={{
          position: 'absolute',
          top: 0,
          left: '18%',
        }}
        spacing={2}
        width='100%'
        height='100%'
        justifyContent='center'
      >
        {user ? (
          <Typography variant='h3'>
            Bonjour, <strong>{`${user.firstName} ${user.lastName}`}</strong>
          </Typography>
        ) : (
          <Skeleton variant='text' width={200} height={30} />
        )}
        {user ? (
          <Typography variant='h6' color='textSecondary'>
            Bienvenue sur votre tableau de bord
          </Typography>
        ) : (
          <Skeleton variant='text' width={150} />
        )}
      </Stack>
      {logo ? (
        <img
          loading='lazy'
          src={logo}
          alt='welcome'
          style={{
            position: 'absolute',
            top: '-5%',
            right: 0,
            height: 180,
            color: 'coral',
          }}
        />
      ) : (
        <AuiSkeleton variant='rectangular' width={200} height={200} />
      )}
    </Box>
  );
};

export default WelcomeHeader;
