/** @format */

import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { useAppSelector } from '@hooks/redux/useSelector';
import { selectSidebar } from '@src/redux/reducer/prefs/PrefSlice';
import { useTranslation } from 'react-i18next';
import { Shell } from 'lucide-react';

interface SidebarHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
}

const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '20px',
  overflow: 'hidden',
  marginBottom: '20px',
}));

const SidebarHeader: React.FC<SidebarHeaderProps> = ({ children, ...rest }) => {
  const theme = useTheme();
  const collapsed = useAppSelector(selectSidebar);
  const { t } = useTranslation();

  return (
    <StyledHeader {...rest}>
      <Box display='flex' alignItems='center' width='100%' gap={2}>
        {!collapsed && (
          <Typography
            variant='h3'
            fontWeight='bold'
            color={theme.palette.warning.main}
          >
            {t('name')}
          </Typography>
        )}
        <Shell size={30} color={theme.palette.warning.main} />
      </Box>
    </StyledHeader>
  );
};

export default SidebarHeader;
