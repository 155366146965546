/** @format */
import { Box, styled, useTheme } from '@mui/material';
import React from 'react';
import ChatHeader from './ChatHeader';
import ChatFooter from './ChatFooter';
import ChatBody from './ChatBody';

const ChatWrapper = styled(Box)(({ theme }) => ({
  flex: 2,
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  borderLeft: `1px solid ${theme.palette.divider}`,
  overflow: 'hidden',
  borderRight: `1px solid ${theme.palette.divider}`,
}));

interface ChatProps {
  border?: boolean;
}

const Chat: React.FC<ChatProps> = ({ border = true }) => {
  const theme = useTheme();
  return (
    <ChatWrapper
      sx={{
        borderLeft: border ? `1px solid ${theme.palette.divider}` : 'none',
        borderRight: border ? `1px solid ${theme.palette.divider}` : 'none',
      }}
    >
      <ChatHeader />
      <ChatBody />
      <ChatFooter />
    </ChatWrapper>
  );
};

export default Chat;
