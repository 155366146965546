/** @format */

import React from 'react';

import { Box, IconButton, Typography, useTheme } from '@mui/material';

import { X as Close } from '@phosphor-icons/react';

interface SettingHeaderProps {
  onClose: () => void;
  title?: string;
}

export const SettingHeader: React.FC<SettingHeaderProps> = ({
  onClose,
  title,
}) => {
  const theme = useTheme();
  return (
    <Box
      width='100%'
      height={'70px'}
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      borderBottom={`1px solid ${theme.palette.divider}`}
      p={2}
    >
      <Typography variant='h6'>{title}</Typography>
      <IconButton onClick={onClose}>
        <Close size={15} />
      </IconButton>
    </Box>
  );
};
