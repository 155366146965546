/** @format */

import React from 'react';
import { Box } from '@mui/material';
import AuiChatMessage from '@src/components/AuiChatItem/AuiChatItem';
import { MessageType } from '@src/components/AuiChatItem/AuiChatItem';
import { AuiBox } from '@components/AuiComponent/AuiComponent';

const messagesData: MessageType[] = [
  {
    id: 1,
    user: 'Floyd Miles',
    avatar: 'https://mui.com/static/images/avatar/1.jpg',
    date: new Date(),
    text: "Hi! Next week we'll start a new project. I'll tell you all the details later",
    type: 'text',
  },
  {
    id: 2,
    user: 'Guy Hawkins',
    avatar: 'https://mui.com/static/images/avatar/2.jpg',
    date: new Date(),
    text: 'Homepage.fig',
    type: 'file',
    fileSize: '13.4 Mb',
  },
  {
    id: 3,
    user: 'Kristin Watson',
    avatar: 'https://mui.com/static/images/avatar/3.jpg',
    date: new Date(),
    text: 'Commented on 7Heros Project',
    type: 'text',
  },
  {
    id: 4,
    user: 'Diane Chambers',
    avatar: 'https://mui.com/static/images/avatar/4.jpg',
    date: new Date(),
    text: 'I have a question about the task',
    type: 'text',
  },
  {
    id: 5,
    user: 'Tina Court',
    avatar: 'https://mui.com/static/images/avatar/5.jpg',
    date: new Date(),
    text: 'I want to discuss the details of the project with you and the team',
    type: 'text',
  },
];

const ChatContent = () => {
  const [messages, setMessages] = React.useState<MessageType[]>(messagesData);
  const [newMessage, setNewMessage] = React.useState('');

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      const newMsg: MessageType = {
        id: new Date().getMilliseconds(),
        user: 'Me',
        avatar: 'https://mui.com/static/images/avatar/4.jpg',
        date: new Date(),
        text: newMessage,
        type: 'text',
        own: true,
      };
      setMessages([...messages, newMsg]);
      setNewMessage('');
    }
  };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AuiBox
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {messages.map((msg) => (
          <AuiChatMessage key={msg.id} message={msg} />
        ))}
      </AuiBox>
    </Box>
  );
};

export default ChatContent;
