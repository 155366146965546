/** @format */

import React from 'react';
import { Box } from '@mui/material';
import { Check } from '@phosphor-icons/react';

interface AccentColorProps {
	color: string;
	selected: boolean;
	toggle?: () => void;
}

const AccentColor: React.FC<AccentColorProps> = ({ color, selected, toggle }) => {
	return (
		<Box
			width='45px'
			height='45px'
			sx={{
				'&:hover': {
					cursor: 'pointer',
				},
			}}>
			<Box
				borderRadius='50%'
				width='100%'
				height='100%'
				bgcolor={color}
				display='flex'
				justifyContent='center'
				alignItems='center'
				sx={{
					transition: 'all 0.3s',
					'&:hover': {
						transform: 'scale(1.1)',
					},
				}}
				onClick={toggle}>
				{selected && <Check weight='regular' size={24} />}
			</Box>
		</Box>
	);
};

export default AccentColor;
