/** @format */

import { createSlice, Slice } from '@reduxjs/toolkit';
import { Session } from '@type/types';
import { clearSessionCookies, getSessionFromCookies, saveSessionToCookies } from '@cookie/Cookies';

const getInitialStateFromCookies = () => {
	const session: Session = getSessionFromCookies();
	return session ? session : { isAuth: false };
};

const initialState: Session = getInitialStateFromCookies();

/**
 * A slice for managing session state in the Redux store.
 * 
 * @constant
 * @name sessionSlice
 * 
 * @property {string} name - The name of the slice.
 * @property {Object} initialState - The initial state of the session.
 * @property {Object} reducers - The reducers for the session slice.
 * @property {Function} reducers.updateSession - A reducer to update the session state.
 * @param {Object} state - The current state of the session.
 * @param {Object} action - The action dispatched to update the session state.
 * @param {Object} action.payload - The new session data to be saved and merged into the state.
 * 
 * @description
 * The `sessionSlice` is responsible for handling the session state within the Redux store.
 * It includes an `updateSession` reducer that saves the session data to cookies and updates
 * the state with the new session data.
 */
export const sessionSlice: Slice<Session> = createSlice({
	name: 'session',
	initialState,
	reducers: {
		updateSession: (state, action) => {
			Object.assign(state, action.payload);
			if (action.payload.remember_me === true) {
				saveSessionToCookies(state);
			}
		},
		clearSession: (state) => {
			clearSessionCookies();
			Object.assign(state, null);
		}
	},
});

export const { updateSession } = sessionSlice.actions;

export default sessionSlice.reducer;
