/** @format */
import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  AreaChart,
  Area,
} from 'recharts';

interface PerformanceData {
  day: string;
  currentMonthHours: number;
  lastMonthHours: number;
}

const PerformanceChart: React.FC = () => {
  const theme = useTheme();
  const performanceData: PerformanceData[] = [
    {
      day: 'Lun',
      currentMonthHours: 1,
      lastMonthHours: 6,
    },
    {
      day: 'Mar',
      currentMonthHours: 4,
      lastMonthHours: 5,
    },
    {
      day: 'Mer',
      currentMonthHours: 5,
      lastMonthHours: 4,
    },
    {
      day: 'Jeu',
      currentMonthHours: 8,
      lastMonthHours: 7,
    },
    {
      day: 'Ven',
      currentMonthHours: 9,
      lastMonthHours: 8,
    },
    {
      day: 'Sam',
      currentMonthHours: 10,
      lastMonthHours: 9,
    },
    {
      day: 'Dim',
      currentMonthHours: 8,
      lastMonthHours: 10,
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        borderRadius: 3,
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <ResponsiveContainer width='100%' height='100%'>
        <AreaChart data={performanceData}>
          <defs>
            <linearGradient id='colorCurrentMonth' x1='0' y1='0' x2='0' y2='1'>
              <stop
                offset='5%'
                stopColor={theme.palette.info.main}
                stopOpacity={0.4}
              />
              <stop
                offset='95%'
                stopColor={theme.palette.info.main}
                stopOpacity={0}
              />
            </linearGradient>
            <linearGradient id='colorLastMonth' x1='0' y1='0' x2='0' y2='1'>
              <stop
                offset='5%'
                stopColor={theme.palette.error.main}
                stopOpacity={0.4}
              />
              <stop
                offset='95%'
                stopColor={theme.palette.error.main}
                stopOpacity={0}
              />
            </linearGradient>
          </defs>
          <CartesianGrid
            strokeDasharray='3 3'
            stroke={theme.palette.divider}
            vertical={false}
          />
          <XAxis
            dataKey='day'
            tickLine={false}
            values='day'
            axisLine={false}
            fontSize={12}
          />
          <YAxis
            domain={[0, 12]}
            values='hours'
            tickFormatter={(value) => `${value}h`}
            fontSize={12}
            tickLine={false}
            axisLine={false}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: theme.palette.primary.main,
              borderRadius: 3,
            }}
            content={({ payload }) => {
              if (payload === undefined) return null;
              if (payload.length > 0) {
                return (
                  <Box
                    sx={{
                      padding: 2,
                      gap: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      backgroundColor: theme.palette.primary.main,
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: 3,
                    }}
                  >
                    <Typography
                      variant='body2'
                      fontWeight='bold'
                      color='textSecondary'
                    >
                      {payload[0].payload.day}
                    </Typography>
                    <Stack spacing={1} justifyContent='center'>
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <Box
                          width={3}
                          height={10}
                          borderRadius={5}
                          bgcolor={payload[0].color}
                        />
                        <Typography variant='body2' color='textSecondary'>
                          Mois actuel
                        </Typography>
                        <Typography
                          variant='body2'
                          fontWeight='bold'
                          color='textSecondary'
                        >
                          {payload[0].payload.currentMonthHours}h
                        </Typography>
                      </Stack>
                      <Stack direction='row' spacing={1} alignItems='center'>
                        <Box
                          width={3}
                          height={10}
                          borderRadius={5}
                          bgcolor={payload[1].color}
                        />
                        <Typography variant='body2' color='textSecondary'>
                          Mois dernier
                        </Typography>
                        <Typography
                          variant='body2'
                          fontWeight='bold'
                          color='textSecondary'
                        >
                          {payload[1].payload.lastMonthHours}h
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                );
              }
              return null;
            }}
          />
          <Area
            type='monotone'
            dataKey='currentMonthHours'
            stroke={theme.palette.info.main}
            fill='url(#colorCurrentMonth)'
            name='Heures ce mois'
          />
          <Area
            type='monotone'
            dataKey='lastMonthHours'
            stroke={theme.palette.error.main}
            fill='url(#colorLastMonth)'
            name='Heures mois dernier'
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default PerformanceChart;
