/**
 * @file /Users/jujumontpre/Documents/epitech/InternalProject/frontend/webts/src/types/schema.ts
 * @format
 * 
 * This file contains schema definitions for form validation using the `zod` library.
 * 
 * The following schemas are defined:
 * 
 * - `EmployeeSchema`: Validates employee form data including first name, last name, email, phone, role, address, and optional birth date.
 * - `UserSchema`: Validates user registration form data including email, password, and confirm password. Ensures passwords match.
 * - `TeamSchema`: Validates team form data including team name, leader, optional description, optional avatar URL, and optional members.
 * - `CompanySchema`: Validates company form data including company name, first name, last name, address, phone, optional SIRET number, and optional APE code.
 * - `LoginCompanySchema`: Validates company login form data including email, password, and optional remember me flag.
 * 
 * Each schema uses `zod` for defining the structure and validation rules, and `validator` for additional validation (e.g., phone number).
 */
/** @format */

import validator from 'validator';
import { z } from 'zod';

/**
 * ? What is the purpose of this code?
 * ! This code is used to define the schema employee form validation
 */

// const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];

// const avatarUrl = z.string().url().optional().refine((url) => {
//   if (!url) return true;
//   const extension = url.split('.').pop()?.toLowerCase();
//   return extension ? imageExtensions.includes(extension) : false;
// }, {
//   message: "URL must point to an image file",
// });

export const EmployeeSchema = z.object({
	firstName: z
		.string()
		.min(2, { message: 'First name must be at least 2 characters long' })
		.max(50, { message: 'First name must be at most 50 characters long' }),
	lastName: z
		.string()
		.min(2, { message: 'Name must be at least 2 characters long' })
		.max(50, { message: 'Name must be at most 50 characters long' }),
	email: z.string().min(1, { message: 'Email is required' }).email(),
	phone: z.string().refine(validator.isMobilePhone, { message: 'Phone number is invalid' }),
	avatarUrl: z.string().url().optional(),
	role: z
		.string()
		.min(2, { message: 'Role must be at least 2 characters long' })
		.max(50, { message: 'Role must be at most 50 characters long' }),

	address: z
		.string()
		.min(2, { message: 'Address must be at least 2 characters long' })
		.max(50, { message: 'Address must be at most 50 characters long' }),
	birthDate: z.string().optional(),
});

export const UserSchema = z
	.object({
		email: z.string().min(1, { message: 'Email is required' }).email(),
		password: z
			.string()
			.min(8, { message: 'Password must be at least 8 characters long' })
			.max(30, { message: 'Password must be at most 30 characters long' })
			.toUpperCase()
			.toLowerCase(),
		confirmPassword: z
			.string()
			.min(8, { message: 'Password must be at least 8 characters long' })
			.max(30, { message: 'Password must be at most 30 characters long' }),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: 'Passwords do not match',
	});

/**
 * ? What is the purpose of this code?
 * ! This code is used to define the schema team form validation
 */

export const TeamSchema = z.object({
	teamName: z
		.string()
		.min(2, { message: 'Team name must be at least 2 characters long' })
		.max(50, { message: 'Team name must be at most 50 characters long' }),
	leader: z.string().min(1, { message: 'Leader is required' }),
	description: z.string().optional(),
	avatarUrl: z.string().url().optional(),
	members: z.array(z.number()).optional(),
});

/**
 * ? What is the purpose of this code?
 * ! This code is used to define the company form validation
 */

export const CompanySchema = z.object({
	companyName: z
		.string()
		.min(2, { message: 'Company name must be at least 2 characters long' })
		.max(50, { message: 'Company name must be at most 50 characters long' }),
	firstName: z
		.string()
		.min(2, { message: 'First name must be at least 2 characters long' })
		.max(50, { message: 'First name must be at most 50 characters long' }),
	lastName: z
		.string()
		.min(2, { message: 'Last name must be at least 2 characters long' })
		.max(50, { message: 'Last name must be at most 50 characters long' }),
	address: z
		.string()
		.min(2, { message: 'Address must be at least 2 characters long' })
		.max(50, { message: 'Address must be at most 50 characters long' }),
	phone: z.string().refine(validator.isMobilePhone, { message: 'Phone number is invalid' }),
	siret: z
		.string()
		.refine((value) => value.toString().length === 14, {
			message: 'SIRET number must be 14 characters long',
		})
		.optional(),
	ape: z.string().min(2).max(50).optional(),
});

export const LoginCompanySchema = z.object({
	email: z.string().min(1, { message: 'Email is required' }).email(),
	password: z
		.string()
		.min(6, { message: 'Password must be at least 8 characters long' })
		.max(30, { message: 'Password must be at most 30 characters long' }),
	remember_me: z.boolean().optional(),
});
