/** @format */

import { RoutePath } from '@src/constants/Constant';
import { useAppSelector } from '@src/hooks/redux/useSelector';
import { RootState } from '@src/redux/store/Store';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';

type ProtectedRouteProps = PropsWithChildren<{}>;

/**
 * A component that protects routes from being accessed by unauthenticated users.
 * It checks the authentication state from the Redux store and redirects to the login page if the user is not authenticated.
 *
 * @param {ProtectedRouteProps} props - The props for the ProtectedRoute component.
 * @param {React.ReactNode} props.children - The child components to render if the user is authenticated.
 * @returns {JSX.Element} - The rendered component or a redirect to the login page.
 */

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const isAuthentificated = useAppSelector(
    (state: RootState) => state.session.isAuth
  );

  if (!isAuthentificated || isAuthentificated === undefined) {
    return <Navigate to={RoutePath.LOGIN} />;
  }

  return <>{children}</>;
}
