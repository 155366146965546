/** @format */

import * as React from 'react';
import AccentColor from '../accent/AccentColor';

import { Box, PaletteMode, Stack, Typography, useTheme } from '@mui/material';
import AuiButton from '@components/AuiButton/AuiButton';
import { useTranslation } from 'react-i18next';
import cfg from '@config/color.json';
import { getCookie } from '@src/cookie/Cookies';
import { StorageKey } from '@src/constants/Constant';
import { MonitorCog, Moon, Sun } from 'lucide-react';
import { ColorModeContext, ExtendedPaletteMode } from '@src/config/theme';
import { enqueueSnackbar } from 'notistack';

interface AppearanceProps {
  activeMode?: ExtendedPaletteMode | undefined;
  handleModeToggle: (mode: ExtendedPaletteMode) => void;
  medium: boolean;
}

const Appearance: React.FC<AppearanceProps> = ({
  activeMode,
  handleModeToggle,
  medium,
}) => {
  const theme = useTheme();
  const activeAccent = getCookie(StorageKey.ACCENT);
  const { t } = useTranslation();
  const { mode, accent } = cfg.default;
  const colorMode = React.useContext(ColorModeContext);
  const systemPrefersDark = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches;
  const resolvedMode =
    activeMode === 'system'
      ? systemPrefersDark
        ? 'dark'
        : 'light'
      : activeMode;

  const [selectedColor, setSelectedColor] = React.useState<boolean[]>(
    cfg.accent.map((color) => (color === activeAccent) as boolean)
  );

  const handleColorToggle = (index: number) => {
    setSelectedColor(() => {
      const newSelected = Array(cfg.accent.length).fill(false);
      newSelected[index] = true;
      colorMode.changeAccent(cfg.accent[index]);
      return newSelected;
    });
  };

  const handleDefault = () => {
    colorMode.changeAccent(accent);
    colorMode.toggleMode(mode as PaletteMode);
    setSelectedColor(() =>
      cfg.accent.map((color) => (color === accent) as boolean)
    );

    enqueueSnackbar(
      <Typography variant='body2'>
        {t('app.settings.appearance.default.message')}
      </Typography>,
      {
        variant: 'default',
      }
    );
  };

  return (
    <Box
      width='100%'
      height='100%'
      padding='20px'
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      gap={'20px'}
    >
      <Stack direction='column' gap={'25px'}>
        <Box display='flex' flexDirection='column' width='100%' gap={'25px'}>
          <Typography variant='body2'>
            {t('app.settings.appearance.description')}
          </Typography>
          <Typography variant='body1'>
            {t('app.settings.appearance.mode')}
          </Typography>
          <Stack direction='row'>
            <AuiButton
              sx={{
                textTransform: 'none',
                borderRadius: '10px 0px 0px 10px',
                fontSize: theme.typography.caption.fontSize,
                borderColor: theme.palette.divider,
              }}
              variant={activeMode === 'light' ? 'contained' : 'outlined'}
              color={resolvedMode === 'light' ? 'secondary' : 'inherit'}
              startIcon={<Sun size={20} />}
              onClick={() => handleModeToggle('light')}
            >
              {!medium && t('app.settings.appearance.light')}
            </AuiButton>
            <AuiButton
              sx={{
                textTransform: 'none',
                borderRadius: '0px',
                fontSize: theme.typography.caption.fontSize,
                borderColor: theme.palette.divider,
              }}
              variant={activeMode === 'system' ? 'contained' : 'outlined'}
              color={activeMode === 'system' ? 'secondary' : 'inherit'}
              startIcon={<MonitorCog size={20} />}
              onClick={() => handleModeToggle('system')}
            >
              {!medium && t('app.settings.appearance.system')}
            </AuiButton>
            <AuiButton
              sx={{
                textTransform: 'none',
                borderRadius: '0px 10px 10px 0px',
                fontSize: theme.typography.caption.fontSize,
                borderColor: theme.palette.divider,
              }}
              variant={activeMode === 'dark' ? 'contained' : 'outlined'}
              color={resolvedMode === 'dark' ? 'secondary' : 'inherit'}
              startIcon={<Moon size={20} />}
              onClick={() => handleModeToggle('dark')}
            >
              {!medium && t('app.settings.appearance.dark')}
            </AuiButton>
          </Stack>
        </Box>
        <Box display='flex' flexDirection='column' width='100%' gap={'25px'}>
          <Typography variant='body1'>
            {t('app.settings.appearance.accent')}
          </Typography>
          <Box display='flex' alignItems='center' flexWrap='wrap' gap={'25px'}>
            {cfg.accent.map((color, index) => (
              <AccentColor
                key={index}
                color={color}
                selected={selectedColor[index]}
                toggle={() => handleColorToggle(index)}
              />
            ))}
          </Box>
        </Box>
      </Stack>
      <AuiButton
        style={{ textTransform: 'none' }}
        variant='text'
        color='secondary'
        onClick={handleDefault}
      >
        {t('app.settings.appearance.default.label')}
      </AuiButton>
    </Box>
  );
};

export default Appearance;
