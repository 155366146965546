/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, useTheme } from '@mui/material';

import { MenuItem } from 'react-pro-sidebar';

interface ItemProps {
  icon?: JSX.Element;
  title: string;
  to: string;
  disabled?: boolean;
  selected?: string;
  onClick?: React.MouseEventHandler<HTMLElement> | undefined;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2';
}

const Item: React.FC<ItemProps> = ({
  icon,
  title,
  to,
  selected,
  disabled = false,
  onClick,
  variant = 'body1',
}) => {
  const theme = useTheme();

  return (
    <MenuItem
      disabled={disabled}
      active={selected === title}
      style={{ color: theme.palette.text.primary }}
      onClick={onClick}
      icon={icon}
      component={<Link to={to} />}
    >
      <Typography fontWeight={400} variant={variant}>
        {title}
      </Typography>
    </MenuItem>
  );
};

export default Item;
