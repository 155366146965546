/** @format */

import { Button, ButtonProps } from '@mui/material';
import React from 'react';

interface AuiButtonProps extends ButtonProps {}

const AuiButton: React.FC<AuiButtonProps> = (props: AuiButtonProps) => {
  return <Button {...props}>{props.children}</Button>;
};

export default AuiButton;
