/** @format */

import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { WandSparkles } from 'lucide-react';

const StyledHeader = styled(Box)(({ theme }) => ({
  flex: 0.6,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: '5px 20px',
  textAlign: 'center',
}));

interface SidebarFooterProps {
  collapsed: boolean;
}

const SidebarFooter: React.FC<SidebarFooterProps> = ({ collapsed }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <StyledHeader
      sx={{
        justifyContent: collapsed ? 'flex-end' : 'space-between',
      }}
    >
      {collapsed ? (
        <a
          href='https://icons8.com/illustrations'
          target='_blank'
          rel='noopener noreferrer'
          style={{ color: 'inherit' }}
        >
          <Avatar
            sx={{
              width: 50,
              height: 50,
              padding: '10px',
              borderRadius: '10px',
              border: `1px solid ${theme.palette.divider}`,
              backgroundColor: alpha(theme.palette.secondary.main, 0.1),
            }}
          >
            <WandSparkles size={30} color={theme.palette.secondary.main} />
          </Avatar>
        </a>
      ) : (
        <Box
          sx={{
            border: '1px solid',
            padding: '10px',
            display: 'flex',
            gap: '10px',
            justifyContent: 'space-between',
            flexDirection: 'column',
            alignItems: 'center',
            width: '85%',
            borderColor: 'divider',
            borderRadius: '10px',
            textAlign: 'center',
          }}
        >
          <Box>
            <Avatar
              sx={{
                width: 50,
                height: 50,
                padding: '10px',
                borderRadius: '10px',
                border: `1px solid ${theme.palette.divider}`,
                backgroundColor: alpha(theme.palette.secondary.main, 0.1),
              }}
            >
              <WandSparkles size={30} color={theme.palette.secondary.main} />
            </Avatar>
          </Box>
          <Typography variant='caption'>
            {t('credits.illustration')}{' '}
            <a
              href='https://icons8.com/illustrations'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: 'inherit', fontWeight: 'bold' }}
            >
              {'Ouch!'}
            </a>
          </Typography>
        </Box>
      )}
      {!collapsed && (
        <Typography variant='caption'>{`© ${new Date()
          .getFullYear()
          .toString()} ${t('copyright')}`}</Typography>
      )}
    </StyledHeader>
  );
};

export default SidebarFooter;
