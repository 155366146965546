/** @format */

import React from 'react';
import { CircularProgress, Box, useTheme } from '@mui/material';

const LoaderSpinner: React.FC = () => {
	const theme = useTheme();
	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
				width: '100%',
				backgroundColor: theme.palette.primary.main,
			}}>
			<CircularProgress color='secondary' />
		</Box>
	);
};

export default LoaderSpinner;
