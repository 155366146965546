/** @format */

import * as React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@styles/app.scss';
import '@styles/layout.scss';
import { store } from '@redux/store/Store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<App />
		</Provider>
	</React.StrictMode>,
);
