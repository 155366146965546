/** @format */

import {
  Accordion,
  Badge,
  Box,
  Tooltip,
  TooltipProps,
  alpha,
  styled,
  tooltipClasses,
  Paper,
  Switch,
  Grid2,
  Skeleton,
} from '@mui/material';

import { DataGrid, GridColumnMenu, gridClasses } from '@mui/x-data-grid';

export const AuiAccordion = styled(Accordion)(({ theme }) => ({
  WebkitFontSmoothing: 'auto',
  overflow: 'hidden',
  backgroundColor: alpha(theme.palette.primary.light, 0.15),
  borderRadius: 2,
  '& .MuiAccordionSummary-root': {
    backgroundColor: alpha(theme.palette.primary.light, 0.7),
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.body1.fontWeight,
    letterSpacing: theme.typography.body1.letterSpacing,
    lineHeight: theme.typography.body1.lineHeight,
    padding: theme.spacing(1),
  },
  '& .MuiAccordionDetails-root': {
    backgroundColor: alpha(theme.palette.primary.light, 0.15),
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    letterSpacing: theme.typography.body2.letterSpacing,
    lineHeight: theme.typography.body2.lineHeight,
  },
  '& .MuiAccordion-root.Mui-expanded': {
    margin: 0,
  },
}));

export const AuiToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(13),
    border: `1px solid ${theme.palette.divider}`,
  },
}));

export const AuiSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.info.main,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? theme.palette.divider
        : theme.palette.divider,
    boxSizing: 'border-box',
  },
}));

export const AuiPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

export const AuiBox = styled(Box)(({ theme }) => ({
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  borderRadius: 3,
  gap: '10px',
  overflowY: 'auto',
  overflowX: 'hidden',
  '&:hover': {
    '::-webkit-scrollbar-thumb': {
      backgroundColor: alpha(theme.palette.secondary.dark, 0.5),
    },
    '::-webkit-scrollbar-track': {
      background: 'transparent',
    },
  },
  '::-webkit-scrollbar': {
    width: '0.4em',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent',
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-track': {
    background: 'transparent',
  },
}));

export const AuiGrid = styled(Grid2)(({ theme }) => ({
  transition: 'all 0.3s ease-in-out',
}));

export const AuiSkeleton = styled(Skeleton)(({ theme }) => ({
  animation: 'wave 1.5s infinite',
}));

export const AuiColumnMenu = styled(GridColumnMenu)(({ theme }) => ({
  WebkitFontSmoothing: 'auto',
  overflow: 'hidden',
  letterSpacing: 'normal',
  backgroundColor: theme.palette.primary.main,
  borderRadius: 8,
  '& .MuiDataGrid-menuList': {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    letterSpacing: theme.typography.body2.letterSpacing,
    lineHeight: theme.typography.body2.lineHeight,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[1],
    borderRadius: 10,
  },
  '& .MuiDataGrid-menuItem': {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    letterSpacing: theme.typography.body2.letterSpacing,
    lineHeight: theme.typography.body2.lineHeight,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  '& .MuiDataGrid-menuItemSelected': {
    backgroundColor: theme.palette.secondary.light,
  },
}));

export const AuiDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 'none',
  outline: 'none',
  backgroundColor: theme.palette.primary.main,
  [`.${gridClasses.root}`]: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    borderTop: `1px solid ${theme.palette.secondary.main}`,
  },
  borderColor: theme.palette.divider,
  [`.${gridClasses.row}`]: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&.Mui-editRow': {
      backgroundColor: theme.palette.info.main,
    },
  },
  [`.${gridClasses['container--top']}`]: {
    border: 'none',
    outline: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`.${gridClasses.columnSeparator}`]: {
    display: 'none',
  },
  [`.${gridClasses.columnsManagementFooter}`]: {
    backgroundColor: theme.palette.secondary.main,
    // display: 'none',
  },
  [`.${gridClasses.footerContainer}`]: {
    outline: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  [`.${gridClasses.cell}`]: {
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  [`.${gridClasses.cellCheckbox}`]: {
    border: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-checkboxInput.Mui-checked': {
    color: theme.palette.secondary.main,
  },
}));

export const AuiBadge = styled(Badge)(({ theme, color }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: color,
    color: color,
    boxShadow: `0 0 0 2px ${color}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));
