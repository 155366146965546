/** @format */

import { Box, styled } from '@mui/material';
import ChatContent from './ChatContent';
import React from 'react';

const StyledContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
}));

const ChatBody: React.FC = () => {
  return (
    <StyledContainer>
      <ChatContent />
    </StyledContainer>
  );
};

export default ChatBody;
