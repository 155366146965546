/** @format */

import { Outlet, createBrowserRouter } from 'react-router-dom';
import { RoutePath } from '@constants/Constant';
import Login from '@src/pages/auth/login/Login';
import Error404 from '@pages/error/Error';
import { AuthLayout, DashboardLayout } from '@src/layouts/Layout';
import DashBoard from '@src/pages/dashboard';
import React from 'react';
import CreateForm from '@src/pages/employee/form/EmployeForm';
import ProtectedRoute from './ProtectedRoute';

const ChatComponenet = React.lazy(() => import('@src/pages/chat'));
const Employees = React.lazy(() => import('@src/pages/employee/Employee'));
const Custom = React.lazy(() => import('@src/pages/custom/Custom'));
const Team = React.lazy(() => import('@src/pages/modules/team'));
const ViewTeam = React.lazy(
  () => import('@src/pages/modules/team/view/viewTeam')
);
const CompanyStepper = React.lazy(
  () => import('@src/pages/auth/company/Company')
);
const EditTeam = React.lazy(() => import('@src/pages/modules/team/edit'));
const HomeCompenent = React.lazy(() => import('@src/pages/home/'));

/**
 * Defines the application's routing configuration using `createBrowserRouter`.
 *
 * Routes:
 * - Root path (`RoutePath.ROOT`):
 *   - Protected route with `DashboardLayout`.
 *   - Redirects to `RoutePath.DASHBOARD`.
 *   - Nested routes:
 *     - `RoutePath.CHAT`: Renders `ChatComponent`.
 *     - `RoutePath.DASHBOARD`: Renders `DashBoard`.
 *     - `RoutePath.EMPLOYEES`: Renders `Outlet` with nested routes:
 *       - Default: Renders `Employees`.
 *       - `RoutePath.ADD_MEMBER`: Renders `CreateForm`.
 *     - `RoutePath.CUSTOM`: Renders `Custom`.
 *     - `RoutePath.PRESENCES`: Renders `Outlet` with nested routes:
 *       - Default: Renders `Team`.
 *       - `RoutePath.VIEW_TEAM`: Renders `ViewTeam`.
 *       - `RoutePath.EDIT_TEAM`: Renders `EditTeam`.
 * - Authentication path (`RoutePath.AUTH`):
 *   - Renders `AuthLayout`.
 *   - Nested routes:
 *     - `RoutePath.SIGNUP`: Renders `CompanyStepper`.
 *     - `RoutePath.LOGIN`: Renders `Login`.
 *
 * Error Handling:
 * - Displays `Error404` component for undefined routes.
 */

export const router = createBrowserRouter([
	{
		path: RoutePath.APP,
		errorElement: <Error404 />,
		element: (
			<ProtectedRoute>
				<DashboardLayout />
			</ProtectedRoute>
		),
		children: [
			{
				path: RoutePath.CHAT,
				element: <ChatComponenet />,
			},
			{
				path: RoutePath.DASHBOARD,
				element: <DashBoard />,
			},
			{
				path: RoutePath.EMPLOYEES,
				element: <Outlet />,
				children: [
					{
						path: '',
						element: <Employees />,
					},
					{
						path: RoutePath.ADD_MEMBER,
						element: <CreateForm />,
					},
				],
			},
			{
				path: RoutePath.CUSTOM,
				element: <Custom />,
			},
			{
				path: RoutePath.PRESENCES,
				element: <Outlet />,
				children: [
					{
						path: '',
						element: <Team />,
					},
					{
						path: RoutePath.VIEW_TEAM,
						element: <ViewTeam />,
					},
					{
						path: RoutePath.EDIT_TEAM,
						element: <EditTeam />,
					},
				],
			},
		],
	},
	{
		path: RoutePath.AUTH,
		element: <AuthLayout />,
		errorElement: <Error404 />,
		children: [
			{
				path: RoutePath.SIGNUP,
				element: <CompanyStepper />,
			},
			{
				path: RoutePath.LOGIN,
				element: <Login />,
			},
		],
	},
	{
		path: RoutePath.ROOT,
		errorElement: <Error404 />,
		children: [
			{
				path: RoutePath.ROOT,
				element: <HomeCompenent />,
			}
		]
	}
]);
