/** @format */

import React from 'react';

import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { LoginType } from '@type/types';
import { LoginCompanySchema } from '@type/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import AuiInput from '@components/AuiInput/AuiInput';
import AuiButton from '@components/AuiButton/AuiButton';

import { Link, useNavigate } from 'react-router-dom';
import { RoutePath } from '@constants/Constant';
import '@src/styles/form.scss';
import { useTranslation } from 'react-i18next';
import { Layers } from 'lucide-react';
import { AuiPaper } from '@src/components/AuiComponent/AuiComponent';
import { useLoginMutation } from '@src/services/auth/Auth';
import { store } from '@src/redux/store/Store';
import { updateSession } from '@src/redux/reducer/session/SessionSlice';

const Login: React.FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<LoginType>({
    resolver: zodResolver(LoginCompanySchema),
  });
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [login, { isLoading }] = useLoginMutation();

  const onSubmit: SubmitHandler<LoginType> = async (data: LoginType) => {
    try {
      const { email, password, remember_me } = data;
      await login({ email, password }).unwrap();
      store.dispatch(updateSession({ remember_me }));
      navigate(RoutePath.DASHBOARD, { replace: true });
    } catch (error) {
      throw error;
    }
  };

  return (
    <Box
      width='100%'
      height='100%'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <AuiPaper
        sx={{
          display: 'flex',
          height: '500px',
          minHeight: '400px',
          width: '20%',
          minWidth: '400px',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          border: `1px solid ${theme.palette.divider}`,
          alignItems: 'center',
          gap: 2,
          padding: '20px',
          borderRadius: 3,
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <form
          className='aui-form'
          onSubmit={handleSubmit(onSubmit)}
          id='admin-form'
        >
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            height='100%'
            justifyContent='space-around'
          >
            <Stack direction='column' spacing={1} alignItems='center'>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Layers size={35} color={theme.palette.secondary.main} />
                <Typography fontWeight='bold' variant='h4'>
                  {t('name')}
                </Typography>
              </Stack>
              <Typography fontWeight='bold' variant='h5' gutterBottom>
                {t('auth.login.title')}
              </Typography>
            </Stack>
            <AuiInput
              control={control}
              name='email'
              label={t('form.fields.email.label')}
              required
              type='email'
              autoComplete='email'
              placeholder={t('form.fields.email.placeholder')}
              error={errors.email}
            />
            <AuiInput
              control={control}
              name='password'
              label={t('form.fields.password.label')}
              required
              type='password'
              autoComplete='current-password'
              placeholder={t('form.fields.password.placeholder')}
              error={errors.password}
            />
            <Stack direction='row' alignItems='flex-start'>
              <Controller
                name='remember_me'
                defaultValue={false}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        color='secondary'
                        name='remember_me'
                        sx={{ m: 1 }}
                      />
                    }
                    label={t('auth.login.remember')}
                  />
                )}
              />
            </Stack>
            <AuiButton
              type='submit'
              variant='contained'
              color='secondary'
              fullWidth
              disabled={isSubmitting || isLoading}
              endIcon={
                isLoading && <CircularProgress size={20} color='inherit' />
              }
              sx={{
                padding: theme.spacing(1),
                borderRadius: 3,
                textTransform: 'none',
                fontWeight: 'bold',
              }}
            >
              {isSubmitting
                ? t('form.buttons.loading')
                : t('form.buttons.login')}
            </AuiButton>
            <Stack
              direction='row'
              spacing={2}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Link
                style={{
                  textDecoration: 'none',
                  color: theme.palette.info.main,
                }}
                to='/forgot-password'
              >
                <Typography variant='caption'>
                  {t('auth.login.forgot')}
                </Typography>
              </Link>
              <Link
                style={{
                  textDecoration: 'none',
                  color: theme.palette.info.main,
                }}
                to={RoutePath.SIGNUP}
              >
                <Typography variant='caption'>
                  {t('auth.login.register')}
                </Typography>
              </Link>
            </Stack>
          </Box>
        </form>
      </AuiPaper>
    </Box>
  );
};

export default Login;
