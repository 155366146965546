/** @format */

import * as React from 'react';

import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface AccountProps {}

const Account: React.FC<AccountProps> = () => {
	const { t } = useTranslation();
	return (
		<Box width='100%' height='100%' display='flex' flexDirection='column' p={2}>
			<Box width='100%' height='100%' gap={2} display='flex' flexDirection='column'>
				<Typography variant='body2'>{t('app.settings.account.description')}</Typography>
				<Typography fontSize={12}>
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae accusantium fuga
					corporis cum totam officiis dolorum blanditiis minima maiores sapiente unde, eligendi
					obcaecati libero ex. Ipsum ullam repudiandae fugiat vitae!
				</Typography>
			</Box>
		</Box>
	);
};

export default Account;
