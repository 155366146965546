/** @format */

import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, InputLabel, Select } from '@mui/material';

interface AuiSelectProps {
	name: string;
	label: string;
	control: any;
	defaultValue: string;
	children: React.ReactNode;
}

const AuiSelect: React.FC<AuiSelectProps> = ({
	name,
	label,
	control,
	defaultValue,
	children,
	...props
}) => {
	const labelId = `${name}-label`;
	return (
		<FormControl {...props} fullWidth>
			<InputLabel color='secondary' id={labelId}>
				{label}
			</InputLabel>
			<Controller
				name={name}
				control={control}
				defaultValue={defaultValue}
				render={({ field }) => (
					<Select color='secondary' labelId={labelId} label={label} {...field} {...props}>
						{children}
					</Select>
				)}
			/>
		</FormControl>
	);
};
export default AuiSelect;
