/** @format */

import React from 'react';
import {
  Modal,
  IconButton,
  useTheme,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { X } from '@phosphor-icons/react';
import { motion } from 'framer-motion';

export interface AuiModalProps {
  open: boolean;
  height?: string;
  width?: string;
  component?: React.ReactNode;
  onClose: () => void;
  children?: React.ReactNode;
  responsive?: boolean;
}

const AuiModal: React.FC<AuiModalProps> = (props) => {
  const {
    open,
    height = '80%',
    width = '450px',
    onClose,
    children,
    responsive,
    component,
  } = props;
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('mobile'));

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none',
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.3 }}
        style={{
          width: phone ? '100%' : width,
          height: phone ? '100%' : height,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          backgroundColor: theme.palette.primary.main,
          borderRadius: responsive ? 0 : '10px',
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        {component && (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            p={theme.spacing(0.5)}
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            {component}
            <IconButton onClick={onClose}>
              <X size={15} />
            </IconButton>
          </Stack>
        )}
        {children}
      </motion.div>
    </Modal>
  );
};

export default AuiModal;
