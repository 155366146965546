/** @format */

import React from 'react';
import { Outlet } from 'react-router-dom';
import TopBar from '@components/appbar/Topbar';
import ProSidebar from '@components/sidebar/Sidebar';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import '@styles/layout.scss';
import '@styles/app.scss';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@src/hooks/redux/useSelector';
import { RootState, store } from '@src/redux/store/Store';
import { updateLng } from '@src/redux/reducer/prefs/PrefSlice';

export function DashboardLayout() {
  return (
    <div className='app'>
      <ProSidebar />
      <main className='content'>
        <TopBar />
        <Outlet />
      </main>
    </div>
  );
}

export function AuthLayout() {
  const lng = useAppSelector((state: RootState) => state.prefs.lng);
  const [value, setValue] = React.useState<string>(lng);
  const { t, i18n } = useTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    event.preventDefault();
    setValue(event.target.value);
    i18n.changeLanguage(event.target.value);
    store.dispatch(updateLng(event.target.value));
  };
  return (
    <div className='aui-layout-wrapper'>
      <header>
        <div className='aui-layout-header'>
          <Stack direction='row' spacing={2} alignItems='center'>
            <FormControl fullWidth>
              <Select
                size='small'
                name='lng-select'
                color='secondary'
                defaultValue={value}
                value={value}
                id='lng-select'
                onChange={handleChange}
              >
                <MenuItem value='en'>🇬🇧 English</MenuItem>
                <MenuItem value='fr'>🇫🇷 Français</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </div>
      </header>
      <Outlet />
      <footer>
        <div className='aui-layout-footer'>
          <Typography variant='subtitle2'>{`© ${new Date()
            .getFullYear()
            .toString()} ${t('copyright')}`}</Typography>
        </div>
      </footer>
    </div>
  );
}
