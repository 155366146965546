/** @format */

import React from 'react';
import {
  InputBase,
  useTheme,
  InputBaseProps,
  Typography,
  Stack,
} from '@mui/material';
import { Search } from 'lucide-react';
import { motion } from 'framer-motion';

interface AuiSearchBarProps extends InputBaseProps {
  shorcut?: string;
}

const AuiSearchBar: React.FC<AuiSearchBarProps> = (
  props: AuiSearchBarProps
) => {
  const theme = useTheme();

  return (
    <motion.div
      initial={{ width: 50, borderWidth: 1 }}
      animate={{
        width: '100%',
      }}
      transition={{ duration: 0.3 }}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        width: '100%',
        gap: theme.spacing(0.5),
        padding: theme.spacing(0.3),
        borderColor: theme.palette.divider,
        borderStyle: 'solid',
        overflow: 'hidden',
      }}
    >
      <Search size={15} />
      <InputBase
        autoFocus
        sx={{
          flex: 1,
          fontSize: '11px',
        }}
        endAdornment={
          props.shorcut && (
            <Stack direction='row' alignItems='center' gap={1}>
              <Typography variant='caption' color='textSecondary'>
                {props.shorcut}
              </Typography>
            </Stack>
          )
        }
        {...props}
      />
    </motion.div>
  );
};

export default AuiSearchBar;
