/** @format */

import { Box } from '@mui/material';
import React from 'react';
// import { useAppSelector } from '@hooks/redux/useSelector';
// import { RootState } from '@redux/store/Store';
import Chat from '@src/pages/chat/chat/Chat';

interface Props {
  user?: any;
}

const Profile: React.FC<Props> = ({ user }) => {
  //   const theme = useTheme();
  //   const session = useAppSelector((state: RootState) => state.session.data);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        gap: 2,
      }}
    >
      {/* <AuiPaper
				sx={{
					display: 'flex',
					alignItems: 'center',
					height: 400,
					width: '100%',
					justifyContent: 'center',
					borderRadius: 5,
					flexDirection: 'column',
					gap: 2,
					// padding: 2,
				}}>
				{user ? (
					<img
						loading='lazy'
						style={{
							width: 130,
							height: 130,
							border: `1px solid ${theme.palette.secondary.main}`,
							borderRadius: '50%',
						}}
						alt='company logo'
						src='https://mui.com/static/images/avatar/2.jpg'
					/>
				) : (
					<Skeleton variant='circular' width={130} height={130} />
				)}
				<Stack spacing={1} direction='row'>
					{user ? (
						<Typography fontWeight='bold' variant='h6'>
							{session?.firstName}
						</Typography>
					) : (
						<Skeleton variant='text' width={130} />
					)}
					{user ? (
						<Typography fontWeight='bold' variant='h6'>
							{session?.lastName}
						</Typography>
					) : (
						<Skeleton variant='text' width={130} />
					)}
				</Stack>
				<Typography color='textSecondary' variant='caption'>
					{session?.email}
				</Typography>
				<Stack spacing={1} direction='row' justifyContent='center' alignItems='center'>
					<Typography fontWeight='bold' variant='h6'>
						{session?.phone}
					</Typography>
					<Typography fontWeight='bold' variant='h6'>
						{session?.address}
					</Typography>
				</Stack>
			</AuiPaper>
			<Stack spacing={1} direction='row' justifyContent='center' alignItems='center'>
				<Box height={'1px'} width={'100%'} bgcolor={theme.palette.divider} />
				<Typography variant='body2' color='textSecondary'>
					Activités
				</Typography>
				<Box height={'1px'} width={'100%'} bgcolor={theme.palette.divider} />
			</Stack> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Chat border={false} />
      </Box>
    </Box>
  );
};

export default Profile;
