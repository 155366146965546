/** @format */

import { Box, Typography } from '@mui/material';
import React from 'react';
import eroorImg from '@assets/images/404.png';

const Error404 = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				width: '100%',
				gap: '20px',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<img src={eroorImg} alt='404 error' />
			<Typography color='textSecondary' variant='h2'>
				Page not found
			</Typography>
		</Box>
	);
};

export default Error404;
