/** @format */

import { updateSession } from '@src/redux/reducer/session/SessionSlice';
import { api } from '@src/redux/store/api';
import { store } from '@src/redux/store/Store';
import { Company, Session } from '@src/types/types';
import route from '@src/config/route.json';

type LoginResponse = any;

type RegisterResponse = any;

/**
 * AuthApi provides authentication-related endpoints for login and registration.
 * 
 * @remarks
 * This service uses RTK Query's `api.injectEndpoints` to define the endpoints.
 * 
 * @public
 */
 
/**
 * @typedef {Object} LoginResponse
 * @property {Object} companyData - The data of the company.
 * @property {string} accessToken - The access token for the session.
 * 
 * @typedef {Object} RegisterResponse
 * 
 * @typedef {Object} Session
 * @property {string} token - The session token.
 * @property {Object} data - The session data.
 * @property {boolean} isAuth - Indicates if the session is authenticated.
 * 
 * @typedef {Object} Company
 * @property {string} name - The name of the company.
 * @property {string} email - The email of the company.
 * @property {string} password - The password for the company account.
 * 
 * @typedef {Object} Endpoint
 * @property {string} LOGIN - The login endpoint URL.
 * @property {string} CREATE_COMPANY - The create company endpoint URL.
 */

/**
 * @function login
 * @description Mutation for logging in a user.
 * @param {Object} body - The login credentials.
 * @param {string} body.email - The email of the user.
 * @param {string} body.password - The password of the user.
 * @returns {LoginResponse} The response from the login endpoint.
 */

/**
 * @function register
 * @description Mutation for registering a new company.
 * @param {Object} body - The company details.
 * @param {string} body.name - The name of the company.
 * @param {string} body.email - The email of the company.
 * @param {string} body.password - The password for the company account.
 * @returns {RegisterResponse} The response from the registration endpoint.
 */

// Helper function to transform the response
const transformLoginResponse = (response: LoginResponse): LoginResponse => {
    const { data, companyAccessToken, message } = response;
    const { _id, ...restData } = data;
    const session: Session = {
        token: companyAccessToken,
        data: {
            id: _id,
            ...restData,
        },
        isAuth: true,
        isAdmin: message.includes('admin') ?? false,
    };
    store.dispatch(updateSession(session));
    return response;
};

export const AuthApi = api.injectEndpoints({
	endpoints: (build) => ({
		login: build.mutation<LoginResponse, { email: string; password: string }>({
			query: (body) => ({
				url: route.shared.login,
				method: 'POST',
				body,
			}),
			transformResponse: (response: LoginResponse) => {
				try {
					return transformLoginResponse(response);
				} catch (error) {
					console.error(error);
					return response;
				}
			},
		}),
		register: build.mutation<RegisterResponse, Omit<Company, 'id' | 'createAt'>>({
			query: (body) => ({
				url: route.company.create,
				method: 'POST',
				body,
			}),
			transformResponse: (response: RegisterResponse) => {
				console.log(response);
				return response;
			},
		}),
	}),
});

export const { useLoginMutation, useRegisterMutation } = AuthApi;
