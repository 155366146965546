/** @format */
import { api } from '../../redux/store/api';
import { Member } from '@src/types/types';
import route from '@src/config/route.json';


export const UserApi = api.injectEndpoints({
	endpoints: (build) => ({
		getEmployees: build.query<Member[], void>({
			query: () => ({
				url: route.employee.list,
			}),
			providesTags: (result) =>
				result
					? [
						{ type: 'Employee' as const, id: 'LIST' },
						...result.map(({id}) => ({ type: 'Employee' as const, id })),
					  ]
					: [{ type: 'Employee' as const, id: 'LIST' }],
			transformResponse: (response: any) => {
				const { data } = response;
				try {
					return data.filter((item: any) => item !== null)
					.map((item: any) => {
					  const { _id, ...restData } = item;
					  return { id: _id, ...restData };
					});
				} catch (error) {
					return data;
				}
			}
		}),
		getEmployee: build.query<Member, number>({
			query: (employeeId) => ({
				url: route.employee.detail.replace(':employeeId', employeeId.toString()),
			}),
			providesTags: (result, error, arg) => [{ type: 'Employee', id: arg }],
		}),
		addEmployee: build.mutation<Member, { employee: Partial<Member>; }>({
			query: ({ employee }) => ({
			  url: route.employee.add,
			  method: 'POST',
			  body: employee,
			}),
			invalidatesTags: [{ type: 'Employee', id: 'LIST' }],
		  }),
		updateEmployee: build.mutation<void, Pick<Member, 'id'> & Partial<Member>>({
			query: ({ id, ...employee }) => ({
				url: route.employee.update.replace(/:id(?!.*:id)/, id.toString()),
				method: 'PUT',
				body: employee,
			}),
			invalidatesTags: (result, _, arg) => [{ type: 'Employee', id: arg.id }],
			async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
				const result = dispatch(
					UserApi.util.updateQueryData('getEmployee', id, (draft) => {
						Object.assign(draft, patch);
					}),
				);
				try {
					await queryFulfilled;
				} catch {
					result.undo();
				}
			},
		}),
		deleteEmployee: build.mutation<{ message: string }, number>({
			query: (employeeId) => ({
				url: route.employee.remove.replace(':employeeId', employeeId.toString()),
				method: 'DELETE',
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Employee', id: arg }],
			transformResponse: (response: any) => {
				const { message } = response;
				try {
					return message;
				} catch (error) {
					return response;
				}
			}
		}),
		qrCode: build.query<string, { employeeId: number }>({
			query: ({ employeeId }) => ({
				url: route.employee.qrcode.replace(':employeeId', employeeId.toString()),
			}),
		}),
		validateQrCode: build.mutation<boolean, { employeeId: number; qrCode: string }>({
			query: ({ employeeId, qrCode }) => ({
				url: route.employee.validateQr.replace(':employeeId', employeeId.toString()),
				method: 'POST',
				body: JSON.stringify({ qrCode }),
			}),
		}),
	}),
});

export const {
	usePrefetch: usePrefetchGetEmployeesQuery,
	useGetEmployeesQuery,
	useGetEmployeeQuery,
	useAddEmployeeMutation,
	useUpdateEmployeeMutation,
	useQrCodeQuery,
	useValidateQrCodeMutation,
	useDeleteEmployeeMutation,
} = UserApi;
