/** @format */

import { Box, colors, Stack, Typography } from '@mui/material';
import React from 'react';
import { AuiBox } from '@components/AuiComponent/AuiComponent';
import Task from '@src/pages/dashboard/component/Task';

import {
	DocumentChartBarIcon,
	DocumentDuplicateIcon,
	DocumentIcon,
} from '@heroicons/react/24/outline';

interface TaskType {
	task: string;
	status: string;
	icon?: React.ReactNode;
	deadline?: string;
	color?: string;
}

const tasks: TaskType[] = [
	{
		task: 'Facturation des clients',
		status: 'In Progress',
		icon: <DocumentIcon color={colors.cyan[500]} style={{ width: 24, height: 24 }} />,
		deadline: '5 jours',
		color: colors.cyan[500],
	},
	{
		task: 'Devis des clients',
		status: 'Completed',
		icon: <DocumentChartBarIcon color={colors.indigo[500]} style={{ width: 24, height: 24 }} />,
		deadline: '2 jour',
		color: colors.indigo[500],
	},
	{
		task: 'Rapport des clients',
		status: 'In Progress',
		icon: (
			<DocumentDuplicateIcon color={colors.deepOrange[500]} style={{ width: 24, height: 24 }} />
		),
		deadline: '3 heure',
		color: colors.deepOrange[500],
	},
];

const TaskHistory: React.FC = () => {
	return (
		<Box height='100%' width='100%' display='flex' gap={1} flexDirection='column'>
			<Stack direction='row' spacing={2} flex={1} height='100%' justifyContent='space-between'>
				<Stack direction='row' spacing={4} flex={1} height='100%' alignItems='center'>
					<Typography fontWeight='bold' variant='h5'>
						Tâches en cours
					</Typography>
					<Typography variant='body2' fontWeight='bold' color='textSecondary'>
						accomplie {tasks.length} %
					</Typography>
				</Stack>
				<Typography variant='body2' fontWeight='bold' color='textSecondary'>
					Liste des tâches en cours
				</Typography>
			</Stack>
			<Box
				sx={{
					overflow: 'hidden',
					width: '100%',
					gap: 2,
					padding: 2,
					borderRadius: 6,
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
				}}>
				<AuiBox
					display='flex'
					flexDirection='column'
					sx={{
						overflowY: 'auto',
						width: '100%',
						height: '100%',
					}}>
					{tasks.map((task, index) => (
						<Task
							key={index}
							task={task.task}
							status={task.status}
							icon={task.icon}
							color={task.color}
							deadline={task.deadline}
						/>
					))}
				</AuiBox>
			</Box>
		</Box>
	);
};

export default TaskHistory;
